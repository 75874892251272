import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { regex } from 'src/app/constants/regex';
import { RegisterPayload, VerifyOtpPayload } from 'src/app/services/auth/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/core/storage/storage.service';
import { ShareDataService } from 'src/app/services/shareData/share-data.service';
import { environment } from 'src/environments/environment';
declare var $: any;


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  password: boolean;
  @ViewChild('onboard') onboard;
  signupForm: FormGroup
  signupFormSubmitted: boolean;
  payload: RegisterPayload;
  fireBaseToken:string='';
  testimonialLink: string = environment.testimonialLink;

  otpInputConfig = {
    length: 6,
    allowNumbersOnly: true,
    disableAutoFocus: false
  }
  termsOfUse: boolean;
  privacyPolicy: boolean;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private shareDataService:ShareDataService,
    private storage:StorageService
  ) {
    this.signupForm = new FormGroup({
      "userName": new FormControl('', Validators.compose([Validators.required])),
      "firstName": new FormControl('', Validators.compose([Validators.required])),
      "lastName": new FormControl('', Validators.compose([Validators.required])),
      "email": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.email)])),
      "password": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.password)])),
      "referralCode": new FormControl('', Validators.compose([]))
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      ({ referralCode }) => {
        if (referralCode) {
          this.signupForm.patchValue({ "referralCode": referralCode });
        }
      }
    );
    // 
    $(document).ready(function () {
      $('body').addClass("modalOpen");
    });
    this.fireBaseToken=this.storage.get('fireBaseToken')
  }
  showPassword() {
    this.password = !this.password;
  }
  ngAfterViewInit() {
    this.openOnboard();
  }

  // Slider
  slides = [
    { title: "Capture", subtitle: "any file...", img: "assets/images/slider1.png", description: "All eight solar arrays on the ISS can generate up to 240 kilowatts of energy by capturing energy from the sun." },
    { title: "STORE", subtitle: "any file, any place...", img: "assets/images/slider2.png", description: "There are 70 septillion stars in the universe" },
    { title: "SHare", subtitle: "any file, any place, anytime...", img: "assets/images/slider3.jpg", description: "Energy produced on the occassion of a supernova is like that of 10^44 joules just as much as the output of the sun in a 10 billion year lifetime." },
  ];

  imageConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "arrows": false,
    'fade': true,
    'dots': true
  }
  openOnboard() {
    // this.modalService.open(this.onboard, { centered: true, windowClass: 'filter-template onboardPopup' });
  }

  registerApiResponse: any;
  resgisterApiErrorResponse: HttpErrorResponse;
  register() {    
    this.signupFormSubmitted = true;
    if (this.signupForm.invalid) {
      return;
    }
    if (!this.termsOfUse) {
      this.commonService.showWarningToastr('Please accept terms of use to complete signup');
      return;
    }
    if (!this.privacyPolicy) {
      this.commonService.showWarningToastr('Please accept privacy policy to complete signup');
      return;
    }
    this.payload = new RegisterPayload(
      {email: this.signupForm.value.email,
        firstName: this.signupForm.value.firstName,
        lastName: this.signupForm.value.lastName,
        password: this.signupForm.value.password,
        referralCode: this.signupForm.value.referralCode,
        userName: this.signupForm.value.userName,
        deviceToken:this.fireBaseToken});
    this.commonService.presentSpinner();
    this.authService.register(this.payload).then(
      (res: any) => {
        if (res) {
          this.commonService.showSuccessToastr(res.message);
          this.registerApiResponse = res;
          // sessionStorage.removeItem('firstVisitMessageShown');

        } else {
          this.commonService.showWarningToastr("Something went wrong. Please try again or later.");
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        this.commonService.errorHandler(error);
        this.resgisterApiErrorResponse = error;
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();

      }
    );
  }

  checkPasswordStrength() {
    let password = this.signupForm.controls['password'] as FormControl;
    let percent = 0;
    if (password.invalid) {
      if (password.value.length > 8) {
        percent = 50;
      } else {
        percent = ((password.value.length / 16) * 100) / 2;
      }
    } else {
      percent = ((password.value.length / 16) * 100);
    }
    return 100 - percent;
  }

  otp: string
  onOtpChange(otp) {
    this.otp = otp;
  }

  otpVerified: number = 3;
  verifyOtp() {
    if (this.otp.length !== 6 || (this.payload && !this.payload.email)) {
      return
    }
    let payload = new VerifyOtpPayload({
      "email": this.payload.email,
      "OTP": this.otp,
      "deviceToken":this.fireBaseToken
    });
    this.otpVerified = 2;
    this.commonService.presentSpinner();
    this.authService.verifyOtp(payload).then(
      ({ userData, message }) => {          
        this.otpVerified = 1
        if (userData) {
          if (userData.isPopupInformation == false) {
              this.shareDataService.indigatePopUpInformation(true);
          }
          localStorage.removeItem('tour');
         

        }
        if(message) {
          this.commonService.showSuccessToastr(message);
          localStorage.removeItem('tour');
        } else {
          this.commonService.showWarningToastr("Something went wrong. Please try again or later.");
        }
      }
      // 
    ).catch(
      (error) => {
        this.commonService.errorHandler(error);
        this.otpVerified = 0;
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        sessionStorage.clear();
      }
    );
  }

  resendOtp() {
    if (this.payload && !this.payload.email) {
      return
    }
    this.commonService.presentSpinner();
    this.authService.resendOtp(this.payload.email).then(
      (res: any) => {
        if(res) {
          this.commonService.showSuccessToastr(res.message);
        } else {
          this.commonService.showWarningToastr("Something went wrong. Please try again or later.");
        }
      }
    ).catch(
      (error) => {
        this.commonService.errorHandler(error);
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    );
  }

  naviGateTo(){
    window.open('https://spacejammit.com/', "_blank");
  }

}