export const endpoints = {
    "auth": {
        "login": "auth/login",
        "register": "auth/register",
        "verifyOtp": "auth/otp/verify",
        "resendOtp": "auth/otp/resend",
        "logout": "auth/logout",
        "profile": "user",
        "stats": "stats",
        "changePassword": "user/change-password",
        "forgotUsername": "auth/forgot-username",
        "forgotPassword": "auth/forgot-password",
        "resetPassword": "auth/reset-password",
        "availableSpace": "user/current-available-space"
    },
    "subscription": {
        "subscriptionPlans": "subscriptions/plans",
        "subscriptionStatus": "user/subscription-status",
        "checkoutForSubscription": "payment/stripe/create-checkout-session",
        "modifySubscription": "payment/stripe/modify-subscription",
        "terminateSubscription": "user/terminate",
        "restoreMission": "user/revert-terminate",
        "leaveTeam": "user/leave-team",
        "manageBilling": "payment/customer-portal",
    },
    "system_Content": {
        "howToVideos": "system/videos",
        "contentAndArchiveImages": "system"
    },
    "misc": {
        "contactUs": "contact",
        "sendInvite": "user/send-invite",
        "teamList": "user/team-list",
        "addTeamMember": "user/add-team-member",
        "removeTeamMember": (member_id: string) => { return `user/remove-team-member/${member_id}` },
        "reportIssue": "report"
    },
    "space": {
        "galaxies": "space/galaxy",
        "createGalaxy": "space/galaxy",
        "updateGalaxy": "space/galaxy/edit",
        "browseGalaxy": "space/galaxy/browse",
        "deleteGalaxy": (galaxy_id: string) => { return `space/galaxy/${galaxy_id}` },
        "removeGalaxy": (galaxy_id: string) => { return `spacestar/remove-access/${galaxy_id}` },
        "uploadStar": "space/star",
        "starDetail": (star_id: string) => { return `space/star/${star_id}` },
        "galaxyDetail": (galaxy_id: string) => { return `space/galaxy-details/${galaxy_id}` },
        "deleteStar": (star_id: string) => { return `space/star/${star_id}` },
        "removeAceesOfStar": (star_id: string) => { return `space/star/remove-access/${star_id}` },
        "favoriteStar": (star_id: string) => { return `space/star/${star_id}` },
        "renameStar": (star_id: string) => { return `space/star/${star_id}` },
        "starByFilter": "space/star",
        "search": "space/search",
        "downloadStar": (star_id: string) => { return `space/star-download/${star_id}` },
        "starMoveDuplicate": (star_id: string) => { return `space/star-move-duplicate/${star_id}` },
        "galaxyDuplicate": (galaxy_id: string) => { return `space/copy-galaxy/${galaxy_id}` },
        "galaxyMoveTo": (galaxy_id: string) => { return `space/move-galaxy/${galaxy_id}` },

        "deleteFiles": "/space/stars/delete-by-ids",
        "deleteFilesPermanent": "/space/stars/delete-stars",
        "bulkShareFiles":(id:string)=>{ return`space/bulk-share/${id}`}

    },
    "publicView": {
        "starView": (linkCode: string) => { return `space/star/p-view/${linkCode}` },
        "galaxyView": (linkCode: string) => { return `space/galaxy/p-view/${linkCode}` }
    },
    "permissionAndShare": {
        "publicShare": (id: string) => { return `space/public-share/${id}` },
        "sharedUsers": (id: string,sort:string) => { return `space/shared-users/${id}`+ (sort ? `?sort=${sort}`:'') },
        "internalShare": (id: string) => { return `space/internal-share/${id}` },
        "toggleInternalShare": (id: string) => { return `space/toggle-internal-share/${id}` },
        "deleteUserAccess": (id: string, user_id: string) => { return `space/delete-user-access/${id}/${user_id}` },
        "deletemultipleUsersAccess": (id: string) => { return `space/remove-bulk-share/${id}` },
        // To set file/space permission type as "team/private/public" in case of group/team
        "fileSpacePermissionForGroup": (id: string) => { return `space/permission/${id}` }
    },
    "recovery": {
        "files": (page: number, limit: number, sort: string, search_key: string) => { return `space/stars/user-deleted-stars?page=${page}&limit=${limit}` + (sort ? `&sort=${sort}` : '') + (search_key ? `&search_key=${search_key}` : '') },
        "restore": "space/stars/restore-by-ids",
       

    },
    "notification": {
        "list": "notifications",
        "resad": "notifications/mark-as-read",
    }
}

// http://localhost:3001/api/v2/space/stars/user-deleted-stars?page=1&limit=10