import { NgModule } from "@angular/core";
import { NoLeadingWhitespacesDirective } from "./no-leading-whitespaces.directive";

@NgModule({
    declarations: [
        NoLeadingWhitespacesDirective
    ],
    imports: [],
    exports: [
        NoLeadingWhitespacesDirective
    ],
    providers: []
})

export class CustomDirectivesModule {}