export const paths = {
    "l": { path: "login", child: {} },
    "su": { path: "sign-up", child: {} },
    "f": { path: "frogot", child: {} },
    "s": { path: "subscriptions", child: {} },
    "tu": { path: "terms-of-use", child: {} },
    "pp": { path: "privacy-policy", child: {} },
    "d": {
        path: "space", child: {
            "sf": { path: 'space-frontier' },
            "g": { path: 'galaxies' },
            "gd": { path: 'galaxy' },
            "ss": { path: 'shining-stars' },
            "mc": { path: 'mission-control' },
            "ad": { path: 'astronaut-detail' },
            "bu": { path: 'beamup' },
            "s": { path: 'search' },
            "htv": { path: 'how-to-videos' },
            "c": { path: 'contact' },
            "ff": { path: 'force-field' },
            "ia": { path: 'invite-astronaut' },
            "as": { path: 'astronaut-status' },
            "v": { path: 'view' },
            "ri": { path: 'report-issue' },
            "mt": { path: 'manage-team' },
            "sp": { path: 'star/permission' },
            "gp": { path: 'galaxy/permission' }
        }
    },
}