import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface PageMetadata {
  title: string;
  image?: string;
  video?: string;
  description: string;
  author: string;
  keywords: string[];
  type: string;
}

const defaultMetadata: PageMetadata = {
  title: 'SpaceJammit',
  description: 'SpaceJammit: Dive into the universe of files and folders',
  author: 'SpaceJammit',
  keywords: [],
  type: 'website',
}

@Injectable()
export class MetadataService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private router: Router
  ) { }

  public updateMetadata(metadata: Partial<PageMetadata>, index: boolean = true): void {
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };
    const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);
    this.metaTagService.addTags([
      ...metatags,
      { property: 'og:url', content: `${environment.webLink}${this.router.url}` },
      //  { name: 'robots', content: index ? 'index, follow' : 'noindex' },
      //  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      //  { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    ]);

    this.titleService.setTitle(pageMetadata.title);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },
      { property: 'twitter:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },
      { property: 'og:image', content: metadata.image },
      { property: 'twitter:image', content: metadata.image },

      { name: 'author', content: metadata.author },
      { property: 'og:author', content: metadata.author },

      { property: 'og:type', content: metadata.type },
    ];
  }
}