import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { regex } from 'src/app/constants/regex';
import { paths } from 'src/app/constants/routing.paths';
import { ResetPasswordPayload } from 'src/app/services/auth/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  password: boolean;
  cPassword: boolean;
  resetPasswordForm: FormGroup;
  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService
  ) {
    this.activatedRoute.queryParams.subscribe(
      ({ token }) => {
        if (token) {
          this.token = token;
        } else {
          this.router.navigate([paths.l.path]);
        }
      }
    );
    this.resetPasswordForm = new FormGroup({
      "token": new FormControl('', Validators.compose([Validators.required])),
      "password": new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.password)])),
      "confirmPassword": new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit(): void {
    this.resetPasswordForm.patchValue({
      'token': this.token
    });
  }

  showPassword(type: 'p' | 'c') {
    switch (type) {
      case 'p':
        this.password = !this.password;
        break;

      case 'c':
        this.cPassword = !this.cPassword;
        break;
    }
  }

  formSubmitted: boolean;
  resetPassword() {
    this.formSubmitted = true;
    if (this.resetPasswordForm.invalid || this.resetPasswordForm.value.confirmPassword !== this.resetPasswordForm.value.password) {
      return;
    }
    let payload = new ResetPasswordPayload(this.resetPasswordForm.value);
    this.commonService.presentSpinner();
    this.authService.resetPassword(payload).then(
      (res) => {
        if(res) {
          this.commonService.showSuccessToastr('Password successfully changed!');
          this.router.navigate([paths.l.path]);
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showWarningToastr(error.error.message);
        } else {
          this.commonService.showWarningToastr('Something went wrong. Please try again or later!');
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }

  checkPasswordStrength() {
    let password = this.resetPasswordForm.controls['password'] as FormControl;
    let percent = 0;
    if (password.invalid) {
      if (password.value.length > 8) {
        percent = 50;
      } else {
        percent = ((password.value.length / 16) * 100) / 2;
      }
    } else {
      percent = ((password.value.length / 16) * 100);
    }
    return 100 - percent;
  }

}
