import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { regex } from 'src/app/constants/regex';
import { paths } from 'src/app/constants/routing.paths';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  type: 'username' | 'password';
  forgotUsername: FormControl;
  forgotPassword: FormControl;

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router
  ) {
    this.route.params.subscribe(
      ({ type }) => {
        if (type) {
          if (type == 'username' || type == 'password') {
            this.type = type;
          } else {
            this._location.back();
          }
        } else {
          this._location.back();
        }
      }
    );
    this.forgotUsername = new FormControl('', Validators.compose([Validators.required, Validators.pattern(regex.email)]));
    this.forgotPassword = new FormControl('', Validators.compose([Validators.required]));
  }

  ngOnInit(): void {
  }

  formSubmitted: boolean;
  recover() {
    this.formSubmitted = true;
    let payload = null;
    let promiseFunction: 'forgotUsername' | 'forgotPassword' = null;
    let message = "";
    switch (this.type) {
      case 'username':
        if (this.forgotUsername.invalid) {
          return;
        }
        payload = { 'email': this.forgotUsername.value };
        promiseFunction = 'forgotUsername';
        message = "A mail has been sent to the entered email with the username in it.";
        break;

      case 'password':
        if (this.forgotPassword.invalid) {
          return;
        }
        payload = { 'userName': this.forgotPassword.value };
        promiseFunction = 'forgotPassword';
        message = "Password recovery link has been sent to the email attached to the entered username.";
        break;
    }
    this.commonService.presentSpinner();
    this.authService[promiseFunction](payload).then(
      (res) => {
        if (res) {
          this.commonService.showSuccessToastr(message);
          this.forgotPassword.reset();
          this.forgotUsername.reset();
          this.formSubmitted = false;
          // this.router.navigate([paths.l.path]);
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showWarningToastr(error.error.message);
        } else {
          this.commonService.showWarningToastr('Something went wrong. Please try again or later!');
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    );

  }

}
