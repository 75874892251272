import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';

@Directive({
  selector: '[NoLeadingWhitespaces]'
})
export class NoLeadingWhitespacesDirective {

  // @HostListener('keypress') onKeyPress(event) {
  //   this.checkLeadingWhiteSpace(event);
  // }
  @HostListener('input') onInput(event) {
    this.checkLeadingWhiteSpace(event);
  }
  // @HostListener('keyup') onKeyUp() {
  //   this.checkLeadingWhiteSpace();
  // }
  // @HostListener('keydown') onKeyDown() {
  //   this.checkLeadingWhiteSpace();
  // }

  constructor(
    private el: ElementRef,
    @Optional() private formControlDir: FormControlDirective,
    @Optional() private formControlName: FormControlName
  ) {
  }

  checkLeadingWhiteSpace(event) {
    const control = this.formControlDir?.control || this.formControlName?.control;
    control.updateValueAndValidity();
    
    if (typeof control.value === 'string') {
      control.setValue(control.value.trim());
    }
    // if (this.el.nativeElement.value.includes(" ")) {
    //   this.el.nativeElement.value = this.el.nativeElement.value.split(" ").join("");
    // }
    // if (this.el.nativeElement.value) {
    //   this.el.nativeElement.value = this.el.nativeElement.value.trim();
    // }
  }

}
