import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginPayload } from 'src/app/services/auth/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/core/storage/storage.service';
import { FireBaseService } from 'src/app/services/firebaseService/fire-base.service';
import { ShareDataService } from 'src/app/services/shareData/share-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  password: boolean;
  loginForm: FormGroup;
  loginFormSubmitted: boolean = false;
  fireBaseToken:string;
  message:any = null;


  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private shareDataService:ShareDataService,
    private storage:StorageService,
    private fireBaseService:FireBaseService
  ) {
    this.fireBaseTokens();

    this.loginForm = new FormGroup({
      'userName': new FormControl('', Validators.compose([Validators.required])),
      'password': new FormControl('', Validators.compose([Validators.required]))
    });
    
  }

  ngOnInit(): void {
   
    
  }
  GoogleAuth(): any {
  
}

  showPassword() {
    this.password = !this.password;
  }

  loginApiResponse: any;
  loginApiErrorResponse: HttpErrorResponse;
  login() {
    this.fireBaseToken=this.storage.get('fireBaseToken')
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let payload = new LoginPayload({userName: this.loginForm.value.userName,
      password: this.loginForm.value.password,
      deviceToken:this.fireBaseToken});
    this.commonService.presentSpinner();
    this.authService.login(payload).then(
      (res:any) => {
   
        if (res.userData.isPopupInformation == false) {
          if(res.userData.isSubscribed==true){
            if (res.userData.subscribedPlan.plan == "space_pilot") {                
            }
            if (res.userData.subscribedPlan.plan == "space_titan") {
            }
            if (res.userData.subscribedPlan.plan == "space_traveler" || res.userData.subscribedPlan.plan == "space_traveller") {  
            }
            // this.popUp();
            this.shareDataService.indigatePopUpInformation(true);
          }else{
            // this.popUp();
            this.shareDataService.indigatePopUpInformation(true);

          }
        // }
  
      }
      }
    ).catch(
      (error) => {
        this.loginApiErrorResponse = error;        
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    );
  }


  fireBaseTokens(){
      this.fireBaseService.requestPermission();
      this.fireBaseService.reciveMessaging();
      this.message=this.fireBaseService.currentMessage;

  }

  naviGateTo(){
    window.open('https://spacejammit.com/', "_blank");
  }


}
