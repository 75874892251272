import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  message: string;
  redirect: string;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(
      ({ msg }) => {
        this.message = msg;
      }
    );
    this.activatedRoute.queryParams.subscribe(
      ({ redirect }) => {
        if (redirect) {
          this.redirect = atob(redirect);
        }
      }
    )
  }

  ngOnInit(): void {
  }

}
