import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/messaging'
import { StorageService } from '../core/storage/storage.service';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class FireBaseService {
  currentMessage=new BehaviorSubject<any>(null);

  constructor(
    private angularFireMessaging:AngularFireMessaging,
    private storage:StorageService,
   ) 
   { 

    let token=this.storage.get('fireBaseToken');
    if(!token){
      this.requestPermission();
    }

      
    }

  requestPermission(){
    this.angularFireMessaging.requestToken.subscribe((fireBaseToken)=>{               
      if(fireBaseToken){
        this.storage.set('fireBaseToken',fireBaseToken)

        // 
      }
    })
  }
  reciveMessaging(){
    this.angularFireMessaging.messages.subscribe((payload)=>{
      this.currentMessage.next(payload);
      // this.commonService.showSuccessToastr('ok')
      
    })
  }
}
