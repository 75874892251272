export class ModifySubscription {
    "priceId": string;
    "action": "UPGRADE" | "DOWNGRADE" | string;

    constructor(data: {
        "priceId": string;
        "action": "UPGRADE" | "DOWNGRADE" | string;
    }) {
        if (data) {
            this.priceId = data.priceId || "";
            this.action = data.action || "";
        }
    }
}