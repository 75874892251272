// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://api.test.app.spacejammit.com/api/v2/",
  androidLink: "https://play.google.com/store/apps/details?id=com.spacejammit.app",
  iosLink: "https://apps.apple.com/us/app/spacejammit/id1577486684",
  webLink: "https://web.test.app.spacejammit.com",
  gracePeriod: 15, //in days only//
  firebase:{
    apiKey: "AIzaSyAxdL4h2x3481Ij8DyEn3bWCQ_HIrq8Em0",
    authDomain: "sjapp-326211.firebaseapp.com",
    projectId: "sjapp-326211",
    storageBucket: "sjapp-326211.appspot.com",
    messagingSenderId: "366555385538",
    appId: "1:366555385538:web:f5f687322659c2b5a1355b",
    measurementId: "G-XV54W4GK91"
  },
  testimonialLink: "https://spacejammit.com/testimonials"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
