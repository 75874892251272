<div class="sj-info" style="background-image: url(assets/images/bg-img.jpg);">
  <div class="sj-container">
    <div class="logo-info" >
      <img src="assets/images/logo.png" class="logo" (click)="naviGateTo()">
      <h3>...any file, any place, anytime...</h3>
    </div>
    <div class="login" [formGroup]="loginForm">
      <div class="form-bg">
        <div class="form-info">
          <h2 class="form-title">Launch!!!</h2>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div *ngIf="loginFormSubmitted && loginForm?.invalid">
                  <p *ngIf="loginForm?.controls['userName']?.errors?.required" class="error-feedback">Please enter
                    username!</p>
                </div>
                <div class="icon-user iconInput">
                  <input type="text" NoLeadingWhitespaces formControlName="userName" class="form-control"
                    placeholder="Enter Username">
                </div>

                <div class="text-right mb-1">
                  <button type="button" class="forgot-link" [routerLink]="['/forgot/username']">Forgot
                    username?</button>
                </div>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group">
                <div *ngIf="loginFormSubmitted && loginForm?.invalid">
                  <p *ngIf="loginForm?.controls['password']?.errors?.required" class="error-feedback">Please enter
                    password!</p>
                </div>
                <div class="icon-password iconInput">
                  <input [type]="password ? 'text' : 'password' " NoLeadingWhitespaces formControlName="password"
                    class="form-control" placeholder="Enter Password">
                  <i (click)="showPassword()" class="custom-eye" [ngClass]="password ? 'sj-eye-open' : 'sj-eye'"></i>
                  <!-- {{loginForm?.invalid}}{{ loginFormSubmitted}} -->
                  <ng-container *ngIf="loginFormSubmitted && loginForm?.invalid">
                    <p *ngIf="loginForm?.controls['password']?.required">
                      Please enter password!</p>
                  </ng-container>
                </div>
                <div class="text-right">
                  <button type="button" class="forgot-link" [routerLink]="['/forgot/password']">Forgot
                    password?</button>
                </div>
                <ng-container *ngIf="loginApiErrorResponse">
                  <p
                    *ngIf="loginFormSubmitted && loginApiErrorResponse && loginApiErrorResponse?.error && loginApiErrorResponse?.error?.message ">
                    {{loginApiErrorResponse?.error?.message | titlecase}}</p>
                </ng-container>
                <ng-container *ngIf="loginApiErrorResponse">
                  <p
                    *ngIf="loginFormSubmitted && (!loginApiErrorResponse || !loginApiErrorResponse?.error || !loginApiErrorResponse?.error?.message)">
                    Something went wrong. Please try again later!</p>
                </ng-container>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-btn">
        <button type="button" class="btn btn-s" (click)="login()">Log In</button>
        <p class="have-account">Don’t have an account? <a href="" [routerLink]="['/sign-up']">Sign Up</a></p>
        <!-- <p class="agree">By signing up you agree to our <a href="">Terms of Use</a> and <a href="">Privacy Policy</a></p> -->
      </div>
    </div>

  </div>
</div>
