import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  @Output() isPopupInformationTracker: EventEmitter<any> = new EventEmitter(); 
  constructor() { }
  indigatePopUpInformation(tracker:any){
    this.isPopupInformationTracker.emit(tracker);
  }

}
