import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { paths } from 'src/app/constants/routing.paths';
import { CommonService } from 'src/app/services/common/common.service';
import { storage_keys } from 'src/app/services/core/storage/storage.keys';
import { StorageService } from 'src/app/services/core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  nonAuthenticationPaths = [
    '/login',
    '/sign-up',
    '/forgot/username',
    '/forgot/password',
    // '/privacy-policy',
    // '/terms-of-use'
  ]

  constructor(
    private storage: StorageService,
    private router: Router,
    private commonService: CommonService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let savedData = this.storage.getSavedData();

    if (state.url.includes('reset-password') && state.url.includes("?token=")) {
      return true;
    }

    let url = state.url.includes('?') ? state.url.split("?")[0] : state.url;
    if ((savedData && savedData[storage_keys.authToken])) {
      if (this.nonAuthenticationPaths.includes(url)) {

        if (!savedData[storage_keys.subscription].accountExpiredWithRenewalPeriod) {
          this.router.navigate([paths.d.path, paths.d.child.sf.path]);
        } else {
          this.router.navigate([paths.s.path]);
        }

        return false;
      } else {
        return true;
      }
    } else {
      if (!this.nonAuthenticationPaths.includes(url)) {

        // If space/view (star view page) url is visited by a non-logged in user
        if (url.includes('/space/view/')) {
          this.router.navigate(['error', `File accessible to SpaceJammit users only. `], { queryParams: { 'redirect': btoa('login') } });
        } else {
          this.router.navigate(['/login']).finally(
            () => {
              // this.commonService.showWarningToastr(`You cannot access this page/link without logging-in.`);
            }
          );
        }
        return false;
      }
      return true;
    }
  }

}
