export class CommonProperties {
    platform = "WEB";
    appVersion = "0.0.0";
    deviceUid = "NA";
    deviceModel = "NA";
    deviceVersion = "NA";
    browser = navigator.userAgent;
    deviceToken='';
}

export class RegisterPayload extends CommonProperties {
    userName: string
    firstName: string
    lastName: string
    email: string
    password: string
    referralCode: string

    constructor(data: {
        userName: string
        firstName: string
        lastName: string
        email: string
        password: string
        referralCode: string
        deviceToken:string
    }) {
        super();
        if (data) {
            this.userName = (data.userName || "").trim();
            this.firstName = data.firstName || "";
            this.lastName = data.lastName || "";
            this.email = (data.email || "").trim();
            this.password = (data.password || "").trim();
            this.referralCode = (data.referralCode || "").trim();
            this.deviceToken = (data.deviceToken || "").trim();
        }
    }
}

export class LoginPayload extends CommonProperties {
    userName: string;
    password: string;
    deviceToken:string;

    constructor(data: {
        userName: string;
        password: string;
        deviceToken:string;

    }) {
        super();
        if (data) {
            this.userName = (data.userName || "").trim();
            this.password = (data.password || "").trim();
            this.deviceToken = (data.deviceToken || "").trim();
        }
    }
}

export class VerifyOtpPayload {
    email: string;
    OTP: string;
    deviceToken='';


    constructor(data: {
        email: string;
        OTP: string;
        deviceToken:string;

    }) {
        if (data) {
            this.email = (data.email).trim();
            this.OTP = data.OTP;
            this.deviceToken = (data.deviceToken || "").trim();

        }
    }
}

export class ChangePasswordPayload {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;

    constructor(data: {
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    }) {
        if (data) {
            this.oldPassword = (data.oldPassword).trim();
            this.newPassword = (data.newPassword).trim();
            this.confirmPassword = (data.confirmPassword).trim();
        }
    }
}

export class ResetPasswordPayload {
    token: string;
    password: string;
    confirmPassword: string;

    constructor(data: {
        token: string;
        password: string;
        confirmPassword: string;
    }) {
        if (data) {
            this.token = (data.token || "").trim();
            this.password = (data.password || "").trim();
            this.confirmPassword = (data.confirmPassword || "").trim();
        }
    }
}