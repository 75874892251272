import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { paths } from 'src/app/constants/routing.paths';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';
import { endpoints } from '../core/http/api.endpoints';
import { HttpRequestService } from '../core/http/http-request.service';
import { storage_keys } from '../core/storage/storage.keys';
import { StorageService } from '../core/storage/storage.service';
import { ModifySubscription } from './subscriptions.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpRequestService,
    private storage: StorageService,
    private router: Router,
    private commonService: CommonService
  ) { }

  getSubscriptionStatus(toDashbord: boolean) {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.subscription.subscriptionStatus).subscribe(
        ({ subscriptionStatus }) => {
          // Function to handle use cases on the basis of subscriptio status
          this.subscriptionsHandler(subscriptionStatus, toDashbord);

          resolve(subscriptionStatus);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  subscriptionsHandler(subscriptionStatus: any, toDashbord: boolean) {
    // Current Timestamp
    let currentTimeStamp = new Date().getTime();

    // Expiry end Date
    let epochDate = new Date(subscriptionStatus.expiryTimestamp * 1000);
    let epochDate1 = new Date(subscriptionStatus.expiryTimestamp * 1000);

    // End Date on the basis of current time zone
    let endDate = new Date(epochDate.setMinutes(epochDate.getMinutes() + new Date().getTimezoneOffset()));

    // Expiry timestamp
    subscriptionStatus['expiryTimestamp'] = endDate.getTime();

    // End date + grace period (15 days) on the basis of current time zone
    let endDateWithRenewalPeriod = new Date(epochDate1.setMinutes(epochDate1.getMinutes() + new Date().getTimezoneOffset()));
    endDateWithRenewalPeriod.setDate(endDateWithRenewalPeriod.getDate() + environment.gracePeriod);
    endDateWithRenewalPeriod = new Date(endDateWithRenewalPeriod);
    subscriptionStatus['expiryDateWithRenewalPeriod'] = endDateWithRenewalPeriod.getTime();

    // To check if account is completed its grace period or not
    subscriptionStatus['accountExpiredWithRenewalPeriod'] = currentTimeStamp > new Date(subscriptionStatus.expiryDateWithRenewalPeriod).getTime();

    // Next Subscription renewal date
    subscriptionStatus['subscriptionRenewalDate'] = endDate.getTime();

    // Expires in days
    let diff = endDate.getTime() - new Date().getTime();
    subscriptionStatus['expiresInDays'] = Math.ceil(diff / (1000 * 60 * 60 * 24));
    // subcription period is ended
    subscriptionStatus['subscriptionPeriodEnded'] = currentTimeStamp > endDate.getTime();

    // To check if subscription is ended and grace period started
    subscriptionStatus['inRenewalPeriod'] = currentTimeStamp > endDate.getTime() && endDateWithRenewalPeriod.getTime() > currentTimeStamp; //

    this.commonService.subscriptionStatus.next(subscriptionStatus);    
    this.storage.set(storage_keys.subscription, subscriptionStatus).then(
      () => {
        // if account expired with GRACE period then page will be auto redirected to subscription page
        if (subscriptionStatus && !subscriptionStatus.accountExpiredWithRenewalPeriod) {

          // If toDashbord set to true then we will automatically redirected to space-frontier page
          if (toDashbord) {
            this.router.navigate([paths.d.path, paths.d.child.sf.path]);
          }
        } else {
          this.router.navigate([paths.s.path]).then(
            () => {
              this.commonService.showWarningToastr('No active plan exists, please subscribe to a plan.');
            }
          );
        }
      }
    );
  }

  getSubscriptionPlans() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.subscription.subscriptionPlans).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  checkoutForSubscription(product_id: string) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.subscription.checkoutForSubscription, { "priceId": product_id }).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  modifySubscription(payload: ModifySubscription) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.subscription.modifySubscription, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  cancelSubscription() {
    return new Promise((resolve, reject) => {
      this.http.put(endpoints.subscription.terminateSubscription, {}).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  restoreSubscription() {
    return new Promise((resolve, reject) => {
      this.http.put(endpoints.subscription.restoreMission, {}).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  leaveTeam() {
    return new Promise((resolve, reject) => {
      this.http.put(endpoints.subscription.leaveTeam, {}).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  manageBillingMethod() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.subscription.manageBilling).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }


}
