import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './services/common/common.service';
import { storage_keys } from './services/core/storage/storage.keys';
import { StorageService } from './services/core/storage/storage.service';
import { SubscriptionService } from './services/subscription/subscription.service';
import { FireBaseService } from './services/firebaseService/fire-base.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'SpaceJammit';
  downloadPercent: number;
  message: any = null;
  constructor(
    private subscriptionService: SubscriptionService,
    private storage: StorageService,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private fireBaseService: FireBaseService
  ) {
    //
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.toastr.clear();
        if (!event.url.includes('/embed/')) {
          this.preventElementInspection();
        }
      }
    });
    let savedData = this.storage.getSavedData();
    if (savedData && savedData[storage_keys.authToken]) {
      this.subscriptionService.getSubscriptionStatus(false);
    }
    // //
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let savedData = this.storage.getSavedData();
        if (savedData && savedData[storage_keys.authToken]) {
          this.subscriptionService.getSubscriptionStatus(false);
        }
      }
    });

    this.commonService.download.subscribe((downloadPercent: number) => {
      this.downloadPercent = downloadPercent;
    });
  }

  ngOnInit(): void {
    let token = this.storage.get('fireBaseToken');
    if (!token) {
      this.fireBaseService.requestPermission();
      this.fireBaseService.reciveMessaging();
      this.message = this.fireBaseService.currentMessage;
    }
  }

  preventElementInspection() {
    /* To Disable Inspect Element */
    $(document).bind('contextmenu', function (e) {
      e.preventDefault();
    });

    // To disable F12 key
    $(document).keydown((e) => {
      if (e.which === 123) {
        return false;
      }
    });

    document.onkeydown = (e: any) => {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)) {
        return false;
      }
      // ctrl + shift + I (prevent element inspection)
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)) {
        return false;
      }
    };
  }
}
