<div class="page-wrap sj-info" style="background-image: url(assets/images/error.jpg);background-position: top;">



    <div class="error-msg">
        <p>{{message}}</p>
        <p *ngIf="redirect && redirect=='login'">Please <a [routerLink]="['/login']">login</a> to access the file.</p>
    </div>
    <div class="error-msg">

    </div>
</div>