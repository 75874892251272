<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ff0000" type="ball-clip-rotate" [fullScreen]="true">
</ngx-spinner>
<!-- <div *ngIf="message;">
  <h1>{{ message.notification.title }}</h1>
  <h2>{{ message.notification.body }}</h2>

</div>

<div *ngIf="!message;">
  No Message Arrived 
</div> \\-->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" name="downloading" size="medium" color="#ff0000" type="ball-clip-rotate"
  [fullScreen]="true">
  <p style="color: #fff;">Downloading ({{downloadPercent || 0}}%)...</p>
  <!-- // -->
</ngx-spinner>