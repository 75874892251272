import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './components/login/login.component';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { RoundProgressModule } from 'ngx-round-progress';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from './services/core/http/http-interceptor.service';
import { CustomDirectivesModule } from './directives/custom-directives.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrModule } from 'ngx-toastr';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ErrorComponent } from './components/error/error.component';
import { MetadataService } from './services/meta-data/meta-data.service';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { environment } from 'src/environments/environment';
// import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {AngularFireMessagingModule }from'@angular/fire/messaging'
import { environment } from 'src/environments/environment';
import { FireBaseService } from './services/firebaseService/fire-base.service';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
// sf


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    SubscriptionsComponent,
    ForgotComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    ResetPasswordComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    CommonModule,
    FormsModule,
    SlickCarouselModule,
    RoundProgressModule,
    HttpClientModule,
    CustomDirectivesModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      timeOut: 5000,
      enableHtml: true
    }),
    InfiniteScrollModule,
    LazyLoadImageModule,
    NgxDocViewerModule,
    // PdfViewerModule,
    
    // AngularFireModule.
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
   
  ],
  providers: [FireBaseService,AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    MetadataService,
    DatePipe
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
