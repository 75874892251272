<div class="header">
  <div class="container">
    <a [routerLink]="['/space/space-frontier']" class="logo">
      <img src="https://admin.test.app.spacejammit.com/assets/images/logo-light.png">
    </a>
  </div>
</div>
<div class="content-page">
  <div class="container">
    <h1 class="heading-title text-center">Privacy Policy</h1>
    <div class="content">
      <h3>General Statement</h3>
      <p>Thank you for using and supporting SpaceJammit – where you can access any file, any place, any time on any
        platform as long as you have internet access on your device. SpaceJammit will provide a unique experience that
        is easy to navigate, convenient, fun, and affordable. Here, we describe how we collect information, how
        information is used, how information is shared, security, data retention, and privacy permissions – in effect
        the SpaceJammit <strong>Privacy Policy</strong>.</p>
      <p>SpaceJammit reserves the right to change, modify, revise or otherwise amend any provision of our Privacy Policy
        by providing notice that the <strong>Privacy Policy</strong> has been modified. Such notice may be provided by
        sending an email, or by posting a notice on the Site, or by posting the revised <strong>Privacy Policy</strong>
        on the Site and revising the date at the top of this <strong>Privacy Policy</strong> or by such other form of
        notice as determined by SpaceJammit. Your use of the Services following the posting of the revised
        <strong>Privacy Policy</strong> or other notice will constitute your acceptance of such changes or
        modifications. Otherwise, any changes or modifications will be effective within thirty (30) days of the posting
        of the revisions unless you notify SpaceJammit within thirty (30) days of the notice posted revision that you do
        not agree to the changes and stop using the Services. </p>
      <p><strong>PLEASE READ THE SPACEJAMMIT PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE SERVICES, BY USING THE
          SPACEJAMMIT PLATFORM, YOU AGREE TO STATEMENTS IN OUR PRIVACY POLICY. </strong></p>
      <h3>Collected Information </h3>
      <p>a. We collect information you provide directly to us. For example, we collect information when you:</p>
      <ul>
        <li>Create a user account</li>
        <li>Share electronic media to other users and non-users</li>
        <li>Contact us for any reason, such as to request help with the Services or to provide us with your
          comments/feedback</li>
        <li>Post information in connection with your electronic media on public social media platforms&nbsp;</li>
        <li>Interactive with other areas of the SpaceJammit Services</li>
        <li>Participate in a survey, contest or sweepstakes</li>
        <li>Otherwise communicate with SpaceJammit (ex: email, etc.)</li>
      </ul>
      <p>The types of information we may collect include but not limited to your name, email address, credit card
        information, bank transfer information, title, gender, and other information you choose to provide.</p>
      <p>You have the option of telling us how you wish to be identified in connection with your
        photos, comments and elsewhere using the Services. If you choose to use your real name (or include any
        identifying information as part of your "nickname") that information will be publicly displayed through the
        Services.</p>
      <p>b. When you access or use our Services, we automatically collect information about your
        use of the Services, including but not limited to:</p>
      <ul>
        <li>Log Information: We log information about your use of the Services, including the type
          of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to
          our Services.</li>
        <li>Device Information: We collect information about the computer or mobile device you use
          to access our Services, including the hardware model, operating system and version, screen resolution, color
          and depth, device identifiers and mobile network information.</li>
        <li>Location Information: we may collect information about the location of your device.
        </li>
        <li>Metadata tied to your electronic media - when you upload your files, some media will
          have metadata tied to your media (depending on file type).</li>
        <li>We use information collected by cookies and other tracking technologies</li>
        <li>We use algorithms to organize and structure your electronic files for search and list
          functionality.</li>
        <li>We may also obtain information from other sources and combine that with information we
          collect through our Services (ex: other social media sites, financial institution billing information that is
          used at time of registration signup)</li>
      </ul>
      <h3>How the Information is Used </h3>
      <p>We use the information we gather for the following purposes:</p>
      <ol>
        <li>To analyze, personalize and improve our Services</li>
        <li>To provide the products and Services you order</li>
        <li>To respond to questions, concerns, or customer service inquiries</li>
        <li>To facilitate contests, sweepstakes, and surveys</li>
        <li>To enforce the legal terms that govern our business and commercial relationships</li>
        <li>To comply with law and legal process and protect rights, safety, and property</li>
        <li>For other purposes requested or permitted by our users or other relevant individuals,
          such as website visitors</li>
      </ol>
      <p>We also may use information to create appropriately aggregated or anonymized data, which
        is not subject to this <strong>Privacy Policy</strong> , and which we may use or disclose
        for any purpose.</p>
      <h3>Shared Information Guidelines</h3>
      <p>a. We may share information about you as follows or as otherwise described in this
        <strong>Privacy Policy</strong>;</p>
      <ul>
        <li>With vendors, consultants and other service providers who need access to such
          information to carry out work on our behalf</li>
        <li>In response to a request for information if we believe disclosure is in accordance
          with any applicable law, regulation or legal process, or as otherwise required by any applicable law, rule or
          regulation</li>
        <li>If we believe your actions are inconsistent with the spirit or language of our user
          agreements or policies, or to protect the rights, property, and safety of SpaceJammit or others</li>
        <li>In connection with, or in anticipation of, any merger, divestiture, consolidation,
          bankruptcy, sale of company assets, financing or acquisition of all or a portion of our business to another
          company, or other significant corporate event (in which case the acquiring entity may use the information
          pursuant to its own privacy policies and procedures, to the extent legally permissible) </li>
        <li>With your consent or at your direction, including if we notify you through our
          Services that the information you provide will be shared in a particular manner and you provide such
          information</li>
        <li>We may also share aggregated or anonymized information that does not directly identify
          you</li>
      </ul>
      <p>Our sharing is governed by our agreement with the account user who hosted it. If you
        contact us regarding a request or complaint about any electronic media files, we may forward your communication
        to the user who hosted it</p>
      <p>The Services may offer social sharing features and other integrated tools which let you
        share actions you take on our Services with other media, and vice versa. The use of such features enables the
        sharing of information with others (public and private - depending on the internal SpaceJammit settings) and any
        3rd party external settings you establish with any entity that provides the social sharing feature and that
        entity’s sharing practices.</p>
      <p>b. The legal grounds that allows SpaceJammit to use or disclose your personal data are
        the following:</p>
      <p><strong>Legitimate interests</strong> We often handle personal data
        because it furthers the legitimate interests of SpaceJammit (or of our customers, affiliates, business partners,
        or suppliers) in business activities such as the ones listed below, and because that handling of data does not
        unduly impact your interests, rights, and freedoms:</p>
      <ul>
        <li>Facilitating free speech, artistic expression, and journalism</li>
        <li>Providing customer service</li>
        <li>Some of our marketing and advertising activities</li>
        <li>Analyzing and improving business activities</li>
        <li>Protecting business activities, individuals, and property (for example, protecting
          against abuse of our Services)</li>
        <li>Managing risks and legal issues.</li>
      </ul>
      <p>Some of our handling of personal data is necessary to meet our contractual obligations to
        individuals, or to take steps at the person's request (ex: billing)</p>
      <p><strong>Consent:</strong></p>
      <ul>
        <li>If the law requires consent, and in some other cases, we handle personal data on the
          basis of consent. For example, we conduct some of our marketing-related activities on the basis of consent.
        </li>
        <li>If the law allows, we may be able to infer consent from the circumstances.</li>
      </ul>
      <p><strong>Legal compliance</strong>: We sometimes need to use and
        disclose personal data to comply with our legal obligations.</p>
      <p><strong>Legal claims</strong>: Sometimes we use or disclose
        personal data because it is necessary to establish, exercise, or defend legal claims.</p>
      <p><strong>Law and Order and the Public Interest</strong> : We may
        disclose your information to third parties if we determine that such disclosure is reasonably necessary to:</p>
      <ul>
        <li>Comply with any applicable law, regulation, legal process, or appropriate government
          request</li>
        <li>Protect any person from death or serious bodily injury</li>
        <li>Prevent fraud or abuse of SpaceJammit or our users</li>
        <li>Protect SpaceJammit’s rights, property, safety, or interest</li>
        <li>Perform a task carried out in the public interest</li>
      </ul>
      <h3>Security</h3>
      <p>SpaceJammit is dedicated to keeping your information secure and testing for vulnerabilities. We continue to
        work on features to keep your information safe (ex: authentication, encryption of files at rest, and alerts) We
        reserve the right to deploy automated technologies to detect abusive behavior and content that may harm our
        Services, you, or other users to help protect information about you from loss, theft, misuse and unauthorized
        access, disclosure, alteration, and destruction. However, no security measure is perfect.
        SpaceJammit cannot and does not promise that any information will remain secure.
      </p>
      <h3>Data Retention
      </h3>
      <p>Data retention and deletion will be followed, dictated, and executed by user plan guidelines. SpaceJammit uses
        third party vendors to host and store any media that is uploaded to SpaceJammit. We may require our vendors to
        maintain certain data standards with respect to your user content, but SpaceJammit does not guarantee that your
        user content will be always secure and/or available during the subscription term. SpaceJammit does not guarantee
        that your user content will be available on the Services after the termination of your account plan,
        irrespective of the reason for such termination. Please note that when user content or other files are deleted
        (or your account is cancelled), those files will be deleted as soon as reasonable after prior communication to
        each user. </p>
      <p>Information in copies made for backup and business continuity may remain for longer than the original data. For
        example, any electronic file marked for deletion, or that are in an account that gets closed, are de-
        published almost immediately and are permanently removed from backup copies at least 30 but up to 60 days later.
      </p>
      <h3>California Residents Privacy Details </h3>
      <p>The sections below apply to “personal information” about California residents, as that term is defined in the
        California Consumer Privacy Act (CCPA), and they supplement the information in the rest of our <b>Privacy
          Policy</b> above. These sections also do not apply to electronic media (defined further above as user-uploaded
        electronic files (ex: certain content relating to such images, videos, and documents), even when such data is
        about a resident of California, because SpaceJammit is a “service provider” for such data under the CCPA.
      </p>
      <p>CCPA categories of California personal information we collect:
      </p>
      <p>In the main part of our <strong>Privacy Policy</strong>, we
        describe the specific pieces of personal information we collect from and about California residents. This
        information generally falls into the following CCPA categories, to the extent that any of the following are
        personally identifiable: identifiers (such as name, address, email address and other contact information);
        commercial information (such as transaction data, and information about an individual’s interactions with
        SpaceJammit); categories of personal information described in California Civil Code 1798.80(e) (such as name);
        characteristics of protected classifications under California or federal law (such as gender); internet or other
        network or device activity, and other information described in the <strong>Collected
          Information</strong> section by Cookies and Other Tracking Technologies section of our <strong>Privacy
          Policy</strong>; geolocation information; professional or employment related
        data (such as title); and other information that identifies or can be reasonably associated with you.</p>
      <p>CCPA description of uses of California personal information:</p>
      <p>In CCPA terms, we have used and disclosed all the categories of California personal information that we collect
        for the purposes described in the <b>How the Information is Used</b> section of our <b>Privacy Policy</b>,
        though some uses and disclosures were more indirect than others. The extent to which our service providers
        engage in the uses and disclosures described above varies from provider to provider. </p>
      <p>CCPA “sale” of California personal information</p>
      <p>SpaceJammit does not “sell” its personal user information. We do not sell copies of our
        user lists to data brokers or other entities performing like a data broker.</p>
      <p>California Privacy Rights</p>
      <p>This section describes rights under California law, but we plan to accept requests under this law from
        non-Californians to the extent practicable and legally permissible. California law may permit you to request
        that we:</p>
      <ul>
        <li>Provide you with a list of the categories of personal information we have collected or disclosed about you
          in the last twelve months; the categories of sources of such information; the business or commercial purpose
          for collecting or selling your personal information; and the categories of third parties with whom we
          disclosed and (if applicable) “sold” personal information in the CCPA sense. </li>
        <li>Provide access to and/or a copy of certain information we hold about you.</li>
        <li>Delete certain information we have about you.</li>
      </ul>
      <p>Certain information is exempt from such requests under applicable law. You also may have the right to receive
        information about the financial incentives that we offer to you (if any). You also have certain rights under the
        CCPA not to be subject to certain negative consequences for exercising CCPA rights. </p>
      <p>Individuals (users and non-users) should contact SpaceJammit support to exercise any rights relating to
        electronic media files, including: </p>
      <ul>
        <li>The documents, images, videos, files, etc. that a user uploads to or creates in the
          Services or Products</li>
        <li>Any labels, tags, comments, descriptions or categorizations that user adds to those
          images and videos in the Services or Products

        </li>
      </ul>

      <p>Contact information can be found in the <strong>Questions and
          Comments</strong> section of the <strong>Privacy Policy</strong> and on SpaceJammit.com
      </p>
      <h3>Questions and Comments</h3>
      <p>Our goal is for each user to be happy with the SpaceJammit experience. If you have any
        questions, concerns, complaints or comments in any way related to your use of the Services, please send
        correspondence on technical questions to <a href="mailto:tech@spacejammit.com"
          style="text-decoration: none;"><u>tech@spacejammit.com </u></a>and general support
        questions to <a href="mailto:support@spacejammit.com"
          style="text-decoration: none;"><u>support@spacejammit.com</u></a></p>
          <p>Revision Date: 2/2/2022</p>
    </div>
  </div>
</div>
<footer class="copyright">©Space Jammit, 2021. All rights reserved.</footer>
