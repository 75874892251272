import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Orientation, OrientationConfiguration } from 'ngx-guided-tour';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from '../core/http/api.endpoints';
import { HttpRequestService } from '../core/http/http-request.service';
import { StorageService } from '../core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  popUpMessage = new Subject();
  data = new Subject();
  deleted = new Subject();
  subscriptionStatus = new Subject();
  sort = new Subject();
  sortForBrowsing: boolean;
  fileOptionsData: any;
  galaxyForAction: any;
  galaxyCreated = new Subject();
  refresh = new Subject();
  download = new Subject();
  upload = new Subject();
  uploadfileName = new Subject();
  isStar: boolean;
  warningPopUpTitle = new Subject<string>();
  warningPopUpMessage = new Subject<string>();
  warningPopUpShowKikiMessage = new Subject<boolean>();
  private shareData = new BehaviorSubject<any>(null);
  private readonly messageKey = 'firstVisitMessageShown';


  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpRequestService,
    private toastrService: ToastrService,
    private storage: StorageService
  ) { }


  showMessageOnFirstVisit(): boolean {
    const messageShown = sessionStorage.getItem(this.messageKey);
    if (!messageShown) {
      sessionStorage.setItem(this.messageKey, 'true');
      return true;
    }
    return false;
  }

  presentSpinner(name?: string) {
    this.spinner.show(name);
  }

  dismissSpinner(name?: string) {
    this.spinner.hide(name);
  }

  setNewInfo(user: any) {
    this.shareData.next(user);
  }
  getNewInfo() {
    return this.shareData.asObservable();
  }
  getHowToVideos() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.system_Content.howToVideos).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  getSystemContentAndArchives() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.system_Content.contentAndArchiveImages).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      )
    });
  }
  showWarningToastr(message: string, title?: string) {
    this.toastrService.warning(message, title, {
      positionClass: "toast-bottom-center"
    })
  }
  showSuccessToastr(message: string, title?: string) {
    this.toastrService.success(message, title, {
      positionClass: "toast-bottom-center"
    })
  }
  notification(message: string, title?: string) {
    this.toastrService.success(message, title, {
      positionClass: "toast-top-right"
    })
  }
  returnPublicLink(type: 'galaxy' | 'star', id: string, transmitSignal: boolean) {
    let link = transmitSignal ? '' : `${environment.baseUrl}space/${id}/view`;
    switch (type) {
      case 'galaxy':
        if (transmitSignal) {
          link = `${environment.webLink}/p-view/galaxy/${id}/view?src=web`;
        }
        break;

      case 'star':
        if (transmitSignal) {
          link = `${environment.webLink}/p-view/star/${id}/view?src=web`;
        }
        break;
    }
    return link;
  }


  errorHandler(error: HttpErrorResponse) {
    if (error && error.error && error.error.message && typeof error.error.message == 'string') {
      this.showWarningToastr(error.error.message);
    } else {
      this.showWarningToastr("Something went wrong. Please try again or later!");
    }
  }

  setView(value: boolean, page: Pages) {
    this.storage.set(page, value ? "list" : "grid");
  }

  getView(page: Pages): boolean {
    let value = this.storage.get(page);
    if (value) {
      return value == 'list' ? true : false;
    } else {
      return false;
    }
  }

  share(linkcode: string) {
    this.showWarningToastr("You can set the file accessible rights from the star permissions.");
    setTimeout(() => {
      navigator.share({ "url": this.returnPublicLink('star', linkcode, true) }).finally(
        () => {

        }
      );
    }, 1000);
  }

  sortByDate = [
    { "action": "date", name: 'Last 7 days', value: '7' },
    { "action": "date", name: 'Last 30 days', value: '30' },
    { "action": "date", name: 'Last 60 days', value: '60' },
    { "action": "date", name: 'No filter', value: '' },
  ];

  blobToFile(theBlob: Blob, fileName: string): File {
    let b: any = theBlob;

    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;

    //Cast to a File() type
    return new File([b], b.name, { type: theBlob.type });
  }
}

export type Pages = 'frontierList' | 'shiningStarList' | 'galaxyList' | 'galaxyDList' | 'pGalaxyList';

