<div class="sj-info" style="background-image: url(assets/images/bg-img.jpg);">
  <div class="sj-container">
    <div class="signup-row" [hidden]="registerApiResponse">
      <div class="signup-left">
        <div class="logo-info" (click)="naviGateTo()">

          <img src="assets/images/logo.png" class="logo">
          <h3>...any file, any place, anytime...</h3>
        </div>
      </div>
      <div class="signup-right">
        <div class="logo-info testimonial-link">
          <h3>
            Seeing is believing - <a [href]="testimonialLink" target="_blank" rel="noopener noreferrer">view our
              testimonials</a> - See for yourself!
          </h3>
        </div>
      </div>
    </div>

    <div class="signup-row">
      <div class="signup-left">
        <div class="sign-up" [formGroup]="signupForm" [hidden]="registerApiResponse">
          <div class="form-bg">
            <div class="form-info">
              <h2 class="form-title">Preparing for<br>blast off... </h2>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="icon-user iconInput">
                      <input type="text" formControlName="firstName" NoLeadingWhitespaces class="form-control"
                        [ngClass]="{'is-invalid': signupFormSubmitted && signupForm?.controls['firstName']?.invalid}"
                        placeholder="Enter First Name">
                      <ng-container *ngIf="signupFormSubmitted && signupForm?.controls['firstName']?.invalid">
                        <p *ngIf="signupForm?.controls['firstName']?.errors?.required" class="error-msg">Enter First
                          Name!
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="icon-user iconInput">
                      <input type="text" formControlName="lastName" NoLeadingWhitespaces class="form-control"
                        [ngClass]="{'is-invalid': signupFormSubmitted && signupForm?.controls['lastName']?.invalid}"
                        placeholder="Enter Last Name">
                      <ng-container *ngIf="signupFormSubmitted && signupForm?.controls['lastName']?.invalid">
                        <p *ngIf="signupForm?.controls['lastName']?.errors?.required" class="error-msg">Enter Last Name!
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="icon-user iconInput">
                      <input type="text" formControlName="userName" NoLeadingWhitespaces class="form-control"
                        [ngClass]="{'is-invalid': signupFormSubmitted && signupForm?.controls['userName']?.invalid}"
                        placeholder="Enter Username">
                      <ng-container *ngIf="signupFormSubmitted && signupForm?.controls['userName']?.invalid">
                        <p *ngIf="signupForm?.controls['userName']?.errors?.required" class="error-msg">Enter Username!
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="icon-email iconInput">
                      <input type="text" formControlName="email" NoLeadingWhitespaces class="form-control"
                        [ngClass]="{'is-invalid': signupFormSubmitted && signupForm?.controls['email']?.invalid}"
                        autocomplete="new-email" spellcheck="false" maxlength="64" placeholder="Enter Email">
                      <ng-container *ngIf="signupFormSubmitted && signupForm?.controls['email']?.invalid">
                        <p *ngIf="signupForm?.controls['email']?.errors?.required" class="error-msg">Enter Email!</p>
                        <p *ngIf="signupForm?.controls['email']?.errors?.pattern" class="error-msg">Enter valid email!
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="icon-password iconInput">
                      <input [type]="password ? 'text' : 'password' " formControlName="password" NoLeadingWhitespaces
                        class="form-control"
                        [ngClass]="{'is-invalid': signupFormSubmitted && signupForm?.controls['password']?.invalid}"
                        autocomplete="new-password" placeholder="Enter Password">
                      <i (click)="showPassword()" class="custom-eye"
                        [ngClass]="password ? 'sj-eye-open' : 'sj-eye'"></i>
                      <ng-container *ngIf="signupFormSubmitted && signupForm?.controls['password']?.invalid">
                        <!-- <p *ngIf="signupForm?.controls['password']?.errors?.required" class="error-msg">Enter Password!</p> -->
                        <!-- <p *ngIf="signupForm?.controls['password']?.errors?.pattern" class="error-msg">Enter Valid Password!
                    </p> -->
                      </ng-container>
                    </div>
                    <div class="error-feedback"
                      *ngIf="signupFormSubmitted && (signupForm?.controls['password']?.errors?.required || signupForm?.controls['password']?.errors?.pattern)">
                      <p>
                        <i>Your SpaceJammit password must contain at least eight characters, a number, an uppercase
                          letter,
                          and a lowercase letter.</i>
                        <br>
                      </p>
                      <p>
                        <span id="progress-bar-container">
                          <span class="progress-bar-child progress"></span>
                          <span [ngStyle]="{'width': checkPasswordStrength() + '%'}"
                            class="progress-bar-child shrinker timelapse"></span>
                        </span><br>
                        <span>Password strength:
                          {{signupForm?.controls['password']?.invalid ? 'Not Acceptable' : 'Acceptable'}}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Referral Code</label>
                    <input type="text" NoLeadingWhitespaces formControlName="referralCode" class="form-control"
                      [ngClass]="{'is-invalid': signupFormSubmitted && signupForm?.controls['referralCode']?.invalid}"
                      placeholder="Optional">
                    <ng-container *ngIf="signupFormSubmitted && signupForm?.controls['referralCode']?.invalid">
                      <p *ngIf="signupForm?.controls['referralCode']?.errors?.required" class="error-msg">Required
                        field!
                      </p>
                    </ng-container>
                  </div>
                </div>
                <!-- <ng-container *ngIf="resgisterApiErrorResponse">
              <p
                *ngIf="signupFormSubmitted && resgisterApiErrorResponse && resgisterApiErrorResponse?.error && resgisterApiErrorResponse?.error?.message; else elseBlock">
                {{resgisterApiErrorResponse?.error?.message | titlecase}}
              </p>
              <p
                *ngIf="signupFormSubmitted && (!resgisterApiErrorResponse || !resgisterApiErrorResponse?.error || !resgisterApiErrorResponse?.error?.message)">
                Something went wrong. Please try again later!
              </p>
            </ng-container> -->
              </div>
            </div>
          </div>
          <div class="form-group form-btn">
            <p class="agree">By signing up you agree to our
              <!-- <a href="">Terms of Use</a> and <a href="">Privacy Policy</a> -->
            </p>
            <div class="form-group agree-checkboxes">
              <div class="form-btn">
                <label class="checkbox-box">
                  <input type="checkbox" name="checkbox" [ngModelOptions]="{'standalone': true}"
                    [(ngModel)]="termsOfUse" id="checkbox">
                  <a class="agree" [routerLink]="['/terms-of-use']" style="text-decoration: none;" target="_blank"
                    href="">Terms of use</a>
                </label>
              </div>
              <div class="form-btn">
                <label class="checkbox-box">
                  <input type="checkbox" [ngModelOptions]="{'standalone': true}" [(ngModel)]="privacyPolicy">
                  <a class="agree" [routerLink]="['/privacy-policy']" style="text-decoration: none;" target="_blank"
                    href="">Privacy
                    Policy</a>
                </label>
              </div>
            </div>
            <button type="button" class="btn btn-s" (click)="register()">Sign Up</button>
            <p class="have-account">Have an account? <a href="" [routerLink]="['/login']">Log In</a></p>

          </div>
        </div>
      </div>


      <div class="signup-right" [hidden]="registerApiResponse">
        <div class="signup-right-text">
          <h2>30 Day free trial <small>with no credit card required</small></h2>
          <ul>
            <li>The easiest platform to send files and receive files. SpaceJammit is accessible on all internet ready
              devices (Windows, IOS, Mac, or Apple).</li>
            <li>Your audience does not need an account to see your content and files shared through SpaceJammit do not
              take up space.</li>
            <li>Video streams within SpaceJammit preserve original top quality and play effortlessly with minimum
              buffering.</li>
            <li>Files are not compressed or altered and upload time for large files meet or exceed existing standards.
            </li>
            <li>Up to 50 individual users can be on the group plan (Space Titan). Get 2 months free with an annual plan.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="signup-row justify-content-center" [hidden]="!registerApiResponse && !registerApiResponse?.isVerified">
      <div class="signup-left">
        <div class="logo-info" (click)="naviGateTo()">

          <img src="assets/images/logo.png" class="logo">
          <h3>...any file, any place, anytime...</h3>
        </div>
        <div class="otp-box">
          <div class="form-bg">
            <div class="form-info">
              <h2 class="form-title">Enter OTP</h2>
              <p>sent on <br>{{payload?.email}}</p>
              <div class="otp-input">
                <!-- <input type="text" class="number">
            <input type="text" class="number">
            <input type="text" class="number">
            <input type="text" class="number">
            <input type="text" class="number">
            <input type="text" class="number"> -->
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="otpInputConfig"></ng-otp-input>
              </div>
              <p *ngIf="otpVerified == 0" class="error-msg">Enter Valid OTP!</p>
            </div>
          </div>
          <div class="form-group form-btn">
            <button type="button" class="btn btn-s" (click)="verifyOtp()">Submit</button>
            <p class="have-account">Didn't receive an OTP? <a style="cursor: pointer;" (click)="resendOtp()">Resend</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Gravity Pull -->
<ng-template #onboard let-modal>
  <div class="onboard-popup">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><img
        src="assets/icons/close-icon.png" class="logo"></button>
    <div class="slider">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="imageConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <div class="slider-info">
            <h3 class="title">{{ slide.title }}</h3>
            <h6 class="subtitle">{{ slide.subtitle }}</h6>
            <img class="slider-img" src="{{ slide.img }}" alt="" width="100%">
            <p class="description">{{ slide.description }}</p>
          </div>
        </div>
      </ngx-slick-carousel>
      <button type="button" class="btn btn-full get-started">Get Started</button>
    </div>
  </div>
</ng-template>