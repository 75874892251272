<div class="sj-info" style="background-image: url(assets/images/bg-img.jpg);">
  <div class="sj-container">
    <div class="logo-info">
      <img src="assets/images/logo.png" class="logo">
      <h3>...any file, any place, anytime...</h3>
    </div>
    <div class="login">
      <div class="form-bg">
        <div class="form-info" *ngIf="type=='username'">
          <button type="button" class="back-btn" [routerLink]="['/login']"></button>
          <h2 class="form-title">Houston<br>We have a <br> problem... </h2>
          <p>To recover your username, please use the form below, We'll send you an email with the username</p>
          <div class="form-group">
            <div class="icon-user iconInput">
              <input type="text" class="form-control" NoLeadingWhitespaces [formControl]="forgotUsername"
                placeholder="Enter Email">
            </div>
            <ng-container *ngIf="formSubmitted && forgotUsername?.invalid">
              <p *ngIf="forgotUsername?.errors?.required" style="color: #fff;" class="error-msg">Required field!
              </p>
              <p *ngIf="forgotUsername?.errors?.pattern" style="color: #fff;" class="error-msg">Please enter valid
                email!
              </p>
            </ng-container>
          </div>
        </div>
        <div class="form-info" *ngIf="type=='password'">
          <button type="button" class="back-btn" [routerLink]="['/login']"></button>
          <h2 class="form-title">Houston<br>We have a <br> problem... </h2>
          <p>To reset your password, please use the form below, We'll send you an email with link to reset your password
          </p>
          <div class="form-group">
            <div class="icon-user iconInput">
              <input type="text" class="form-control" NoLeadingWhitespaces [formControl]="forgotPassword"
                placeholder="Enter Username">
            </div>
            <ng-container *ngIf="formSubmitted && forgotPassword?.invalid">
              <p *ngIf="forgotPassword?.errors?.required" style="color: #fff;" class="error-msg">Required field!
              </p>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-group form-btn">
        <button type="button" class="btn btn-s" (click)="recover()">Submit</button>
        <p class="have-account">Don’t have an account? <a href="" [routerLink]="['/sign-up']">Sign Up</a></p>
      </div>
    </div>

  </div>
</div>
