export enum SUBSCRIPTIONS {
    SPACE_TRAVELLER = "space_traveler",
    SPACE_PILOT = "space_pilot",
    SPACE_TITAN = "space_titan",
    SPACE_TRIAL = "space_trial"
};

export enum ACCOUNT_TYPE {
    SHARED = "shared",
    INDIVIDUAL = "individual"
};