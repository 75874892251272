<div class="header">
  <div class="container">
    <a [routerLink]="['/space/space-frontier']" class="logo">
      <img src="https://admin.test.app.spacejammit.com/assets/images/logo-light.png">
    </a>
  </div>
</div>
<div class="content-page">
  <div class="container">
    <h1 class="heading-title text-center">Terms & conditions</h1>
          <div class="content">
            <h3>General Statement</h3>
            <p>Welcome to SpaceJammit. At SpaceJammit, you can access any file, any place, any time. No matter what platform or file
                type. All you need is a device and internet access. Our mission is to provide a unique experience that is easy to
                navigate, convenient, fun, and affordable. Our platform will keep your content safe and accessible while helping you
                store and share your files with other people. These <b>TERMS OF USE</b>
            </p>
            <p> <b>(“Terms”)</b> cover your use and access to our
                Services, client software and
                websites. Our Privacy Policy explains how we collect and use your information while our Acceptable
                Use guidelines outlines your responsibilities when using our Services. By using our Services, you’re
                agreeing to be bound by these <b>TERMS</b>, our <b>Privacy Policy</b>, and Acceptable Use Policy.</p>
            <p>The Services are not targeted towards, nor intended for use by, anyone under the age of 15. If you are between the
                ages of 16 and 18, you may use the Services only under the supervision of a parent or legal guardian who agrees to
                be bound by these <b>TERMS OF USE</b>.
            </p>
            <p>SpaceJammit reserves the right to change, modify, revise, or otherwise amend any provision of these <b>TERMS OF
                    USE</b>, and
                any other terms, policies or guidelines governing your use of the Services, at any time at its sole discretion by
                providing notice that the <b>TERMS OF USE</b> have been modified. Such notice may be provided by sending an email,
                or by
                posting a notice on the Site, or by posting the revised <b>TERMS OF USE</b> on the Site and revising the date at the
                top of
                these <b>TERMS OF USE</b> notice or by such other form of notice as determined by SpaceJammit. Your use of the
                Services
                following the posting of the revised <b>TERMS OF USE</b> or other notice will constitute your acceptance of such
                changes or
                modifications. Otherwise, any changes or modifications will be effective within thirty (30) days of the posting of
                the
                revisions unless you notify SpaceJammit within thirty (30) days of the notice posted revision that you do not agree
                to
                the changes and stop using the Services.
            </p>
            <p>We may revise these <b>TERMS</b> from time to time to better reflect:</p>
            <ul>
                <li>Changes to the law,</li>
                <li>New regulatory requirements, or</li>
                <li>Improvements or enhancements made to our Services.</li>
            </ul>
            <p><b>PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING THE SERVICES, BY USING THE SPACEJAMMIT PLATFORM,
                    YOU AGREE TO BE BOUND BY THESE TERMS OF USE.</b></p>
            
            <h3>Your Content</h3>
            <p> When you use our Services, you provide us with things like your electronic media files, content, contacts, etc. This
                is “your content”. Your content is your intellectual property (IP). These <b>TERMS</b> do not give SpaceJammit any
                rights to your content except for the limited rights that enable us to offer the Services. </p>
            <p>We need your permission to do things like hosting your content, data retention / file storage, and sharing your
                content when you ask us to. Our Services also provide you with features like commenting, sharing, searching, image
                thumbnails, electronic media previews, easy sorting and organization, personalization, etc. to help enhance the user
                experience. To provide these and other features, SpaceJammit accesses, stores, and scans your content. You give us
                permission to do those things, and this permission extends to our affiliates and trusted third parties that
                SpaceJammit works with.</p>
            <h3>Our Content</h3>
            <p>The Services and all images, software, platforms, tools, graphics, data, text, source code, and other content and
                materials available on the Services (excluding User Content) and the selection and arrangement thereof are the
                property of SpaceJammit or its third-party licensors and are protected by United States and international
                intellectual property laws.</p>
            <p>SpaceJammit hereby grants you a limited, non-transferable, non-sublicensable, revocable license to access and use the
                SpaceJammit platform solely in accordance with these <b>TERMS OF USE</b>. Except for the limited licenses granted
                hereunder, SpaceJammit reserves all rights not expressly granted and no such additional rights may be implied. You
                acknowledge that (i) all right, title and interest in and to SpaceJammit
                Materials, including all patents, copyrights, trade secrets, trademarks and other proprietary rights embodied
                therein or associated therewith, are and will remain with SpaceJammit or its third party licensors; (ii) no right or
                interest in SpaceJammit Materials is conveyed other than the limited licenses granted herein; (iii) SpaceJammit
                Materials are protected by copyright and other intellectual property laws; and (iv) SpaceJammit asserts that
                SpaceJammit Materials embody valuable confidential and secret information of SpaceJammit or its licensors, the
                development of which required the expenditure of considerable time and money.
            </p>
            
            
            
            <p>You acknowledge that SpaceJammit, the SpaceJammit logo the look and feel of the Services, and any other Product or
                Service names, logos, or slogans of SpaceJammit contained in the Services are trademarks of SpaceJammit may not be
                copied, imitated, or used without the prior written permission of SpaceJammit. All other trademarks, registered
                trademarks, product names and company names or logos mentioned in the Services are the property of their respective
                owners.
            </p>
            <h3>Restrictions</h3>
            <p>You agree that you will not (i) modify or alter any SpaceJammit Materials; (ii) create derivative works of the
                SpaceJammit Materials; (iii) decompile, disassemble, decode or reverse engineer SpaceJammit Materials, translate the
                SpaceJammit Materials or otherwise attempt to learn the source code, structure, algorithms or internal ideas
                underlying the SpaceJammit Materials or reduce the SpaceJammit Materials by any other means to a human-perceivable
                form; or (iv) bypass, delete or disable any copy protection mechanisms or any security mechanisms in the SpaceJammit
                Materials.
            </p>
            <p>Except as otherwise expressly permitted herein, you may not use the Services or the SpaceJammit Materials to engage
                in any of the following prohibited activities:
            </p>
            <ul>
                <li>The collection, copying or distribution of any portion of the SpaceJammit Materials</li>
                <li>Any resale, commercial use, commercial exploitation, distribution, public performance or public display of the
                    Services or SpaceJammit Materials</li>
                <li>Modifying or otherwise making any derivative uses of the Services or SpaceJammit Materials</li>
                <li>Scraping or otherwise using any data mining, robots or similar data gathering or extraction methods on or in
                    connection with the Services</li>
                <li>With the exception of user content made available by users for download, the downloading of any portion of the
                    SpaceJammit Materials or any information contained therein</li>
                <li>Any use of the Services or SpaceJammit Materials other than for their intended purposes.</li>
            </ul>
            <p>Any use of the Services or of any SpaceJammit Materials other than as specifically authorized herein, without the
                express prior written permission of SpaceJammit and the applicable SpaceJammit user or the Content Owner, is
                strictly prohibited. Any such unauthorized use may result in the immediate termination of your rights under these
                <b>TERMS OF USE</b> and will constitute a breach of the license granted herein.
            </p>
            
            
            
            <h3>Acceptable Use</h3>
            <p>You agree not to misuse SpaceJammit services ("Services") or help anyone else to do so unless obtained exclusive
                written permission from SpaceJammit. This includes but is not limited to the following examples: </p>
            <ul>
                <li>Probe, scan, or test the vulnerability of any system or network</li>
                <li>Breach or otherwise circumvent any security or authentication measures </li>
                <li>Access, tamper with, or use non-public areas or parts of the Services, or shared areas of the Services you have
                    not been invited to </li>
                <li>Interfere with or disrupt any user, host, or network, for example by sending a virus, overloading, flooding,
                    spamming, or mail-bombing any part of the Services</li>
                <li>Access, search, or create accounts for the Services by any means other than our publicly supported interfaces
                    (for example, "scraping" or creating accounts in bulk) </li>
                <li>Send unsolicited communications, promotions or advertisements, or spam</li>
                <li>Send altered, deceptive, or false source-identifying information, including "spoofing" or "phishing"
                </li>
                <li>Promote or advertise products or services other than your own without appropriate authorization
                </li>
                <li>Abuse referrals or promotions to get more storage space than deserved or to sell storage space received from
                    referrals or promotions</li>
                <li>Circumvent storage space limits</li>
                <li>Sell the Services unless specifically authorized to do so</li>
                <li>Publish or share materials that are unlawfully pornographic or indecent, or that contain extreme acts of
                    violence or terrorist activity, including terror propaganda</li>
                <li>Advocate bigotry or hatred against any person or group of people based on their race, religion, ethnicity, sex,
                    gender identity, sexual orientation, disability, or impairment</li>
                <li>Harass or abuse SpaceJammit personnel, representatives, or agents performing services on behalf of SpaceJammit
                </li>
                <li>Violate the law in any way, including storing, publishing, or sharing material that's fraudulent, defamatory, or
                    misleading</li>
                <li>Violate the privacy or infringe the rights of others</li>
            </ul>
            
            
            <h3>Your Account
            </h3>
            <p>Storing and sharing electronic content requires a user account. To create an account, you must be 15 years of age or
                older. </p>
            <ul>
                <li>By registering for an account, you represent and warrant that you are at least 15 years of age and&nbsp; that
                    all information you provide at the time of sign up is complete and accurate.&nbsp;&nbsp;</li>
                <li>SpaceJammit reserves the right to terminate your account and/or refuse access to the Services to&nbsp; anyone at
                    any time, with or without cause at its sole discretion.&nbsp;&nbsp;</li>
                <li>If SpaceJammit terminates your account due to inactivity, SpaceJammit will use reasonable efforts&nbsp; to
                    notify you of such termination in advance by emailing such notice to the email address you have&nbsp; specified
                    in your account.&nbsp;</li>
                <li>You agree to keep your registered email addresses and other user related data current and&nbsp; complete, as
                    SpaceJammit may send important notices about your account from time to time. </li>
                    <li>By registering for an account, you consent to receive communications from SpaceJammit about  the Services by email and/or system messages consistent with the terms of our<b>Privacy Policy.</b></li>
            </ul>
            
            <h3>Account Security</h3>
            <p>SpaceJammit considers your user account and information very sensitive information and uses the most recent
                technology approaches to protect your account. You are solely responsible for maintaining the confidentiality of the
                passwords associated with your account and for restricting access to your passwords and physical access to your
                electronic devices while logged into the Services. You accept responsibility for all activities that occur under
                your user account.</p>
            <p>You understand and acknowledge that any user content hosted and consequently shared (including any user galleries or
                other portions of the Services) is accessible to the public and could be accessed, downloaded, indexed, archived,
                linked to, and republished by others including, without limitation, appearing on other websites and in search engine
                results. SpaceJammit provides account plans with security options / settings to restrict the sharing of your
                content. This information can be compared by reviewing the different account plan options.
            </p>
            <p>We use commercially reasonable security measures to protect your user data and your content. We cannot, however,
                guarantee absolute security of your account, your content, or the user data we collect, and we cannot promise that
                our security measures will prevent third party “hackers” or other unauthorized parties from illegally accessing the
                Services or their contents. SpaceJammit is not responsible or liable for any third-party access to or use of your
                content you host and / or share or your user data. You agree to immediately notify SpaceJammit of any unauthorized
                use of your account or passwords or any other breach of security, and you accept all risks of unauthorized access to
                your user data, your content, and any other information you provide to SpaceJammit. </p>
            <h3>3rd Party Services </h3>
            <p>SpaceJammit may make third party content and Services available on or through the Services (&ldquo;Third Party&nbsp;
                Services&rdquo;) solely as a convenience to its users, or because a user shared or made it accessible (for&nbsp;
                example, links to third party websites, software, and other Services). When you leave the Services, you&nbsp;
                should be aware that these <strong>TERMS OF USE </strong> and all other SpaceJammit policies no longer
                govern your use&nbsp; of such websites and Services, or any content contained thereon.</p>
            
            <p>SpaceJammit does not indicate affiliation, approval, or control of any Third-Party Services by making such&nbsp;
                Third-Party Services available via the Services. SpaceJammit makes no claim or representation regarding,&nbsp; and
                accepts no responsibility for, the quality, accuracy, nature, ownership, or reliability of Third-Party Services.
            </p>
            <h3>Advertisements / Promotions
            </h3>
            <p>Occasionally, hosted and shared user content may contain third party advertisements and promotions generated or
                posted by other users of the Services, SpaceJammit Vendors, or other third-party service providers.</p>
            <p>Any of your business dealings or interactions with any third parties, including other users of the Services or
                SpaceJammit Vendors, and any terms, conditions, warranties, or representations associated with such dealings, are
                solely between you and such third party. SpaceJammit does not endorse, approve, or control any such products,
                Services, advertising, or promotions posted to the Services by its users or the SpaceJammit Vendors or third-party
                service providers. SpaceJammit is not responsible or liable for any loss or damage of any kind incurred as the
                result of your direct dealings with any of them or otherwise resulting from the presence of advertisements for third
                party products and Services included on the Services.
            </p>
            <h3>Warranties
            </h3>
            <p>
                <b>TO THE FULLEST EXTENT PERMITTED BY LAW, SPACEJAMMIT MAKES NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE
                    SERVICES. THE SERVICES ARE PROVIDED "AS IS." WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE, THE SERVICES, THE SPACEJAMMIT MATERIALS AND
                    PRODUCTS.
                </b>
            </p>
            <p>
                <b>SPACEJAMMIT DOES NOT REPRESENT OR WARRANT THAT THE SPACEJAMMIT MATERIALS OR THE SERVICES ARE ACCURATE, COMPLETE,
                    RELIABLE, CURRENT, OR ERROR FREE OR THAT THE SERVICES, ITS SERVERS OR EMAIL SENT FROM SPACEJAMMIT ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS. SPACEJAMMIT IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS
                    RELATING TO PRICING, TEXT, PHOTOS OR VIDEOS. SPACEJAMMIT
                    ALSO MAKES NO REPRESENTATION OR WARRANTY REGARDING THE AVAILABILITY, RELIABILITY OR SECURITY OF THE SERVICES AND
                    WILL NOT BE LIABLE FOR ANY UNAUTHORIZED ACCESS TO OR ANY MODIFICATION, SUSPENSION, UNAVAILABILITY, OR
                    DISCONTINUANCE OF THE SERVICES OR THE PRODUCTS PROVIDED THEREON.
                    Limitation of Liability
            
                </b>
            </p>
            
            <h3>Limitation of Liability</h3>
            <p><b>IN NO EVENT WILL SPACEJAMMIT OR ITS DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY SPECIAL, INDIRECT OR
                    CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF
                    PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
                    CONNECTED WITH THE USE OF OR INABILITY TO USE OR VIEW THE SITE, THE SERVICES, THE PRODUCTS, THE USER CONTENT OR
                    SPACEJAMMIT MATERIALS CONTAINED IN OR ACCESSED THROUGH THE SERVICES, INCLUDING ANY DAMAGES CAUSED BY OR
                    RESULTING FROM YOUR RELIANCE ON ANY INFORMATION OBTAINED FROM SPACEJAMMIT OR THAT RESULT FROM MISTAKES,
                    OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR
                    TRANSMISSION OR ANY TERMINATION, SUSPENSION OR OTHER FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS
                    OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO SMUGMUG’S RECORDS, PROGRAMS OR
                    SERVICES. </b></p>
            <p><b>IN NO EVENT WILL THE AGGREGATE LIABILITY OF SPACEJAMMIT, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
                    NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING
                    OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SITE, THE SERVICES, THE PRODUCTS, THE USER CONTENT OR
                    SPACEJAMMIT MATERIALS, EXCEED COMPENSATION YOU PAY, IF ANY, TO SPACEJAMMIT FOR ACCESS TO OR USE OF THE SITE OR
                    THE SERVICES OR FOR THE PURCHASE OF PRODUCTS. </b></p>
            <p><b>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                    DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
                    APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS. </b></p>
            
            
            
            <h3>Indemnity
            </h3>
            <p>You agree to defend, indemnify and hold harmless SpaceJammit and its affiliates and subsidiaries (collectively, the
                “Indemnified Parties”), and the Indemnified Parties’ independent contractors, service providers and consultants, and
                their respective directors, employees and agents, from and against any claims, damages, costs, liabilities and
                expenses (including reasonable attorneys’ fees) arising out of or
                related to any user content you post, are recipient of, or otherwise transmit on or through the Services, your use
                of or inability to use the Services, the user content, or SpaceJammit Materials, including any actual or threatened
                suit, demand or claim made against any of the Indemnified Parties and/or their independent contractors, service
                providers, employees, directors or consultants, arising out of or relating to your user content or other user
                content received, your conduct, your violation of these TERMS OF USE or your violation of the rights of any third
                party.
            </p>
            <h3>Dispute Resolution</h3>
            <p>Your use of the Services is subject to all applicable local, state, national and international laws, and regulations.
                These <b>TERMS OF USE</b> and your use of the Services will be governed by and construed in accordance with the laws
                of the State of Delaware applicable to agreements made and to be entirely performed within the State of Delaware,
                without regard to its conflict of law provisions.
            </p>
            <p>You and SpaceJammit agree to arbitrate any dispute arising from these <b>TERMS OF USE</b> or relating to the
                Services, Site or SpaceJammit Materials. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL.
                You and SpaceJammit agree: (i) to notify each other of any dispute within thirty (30) days of when it arises; (ii)
                to attempt informal resolution prior to any demand for arbitration; (iii) that any arbitration will occur in New
                Castle County, Delaware; and (iv) that arbitration will be conducted confidentially by a single arbitrator in
                accordance with the Rules of the American Arbitration Association (“AAA”), including the AAA’s Supplementary
                Procedures for Consumer-Related Disputes (as applicable). The AAA’s rules are available at www.adr.org. Other than
                class procedures and remedies described in these <b>TERMS OF USE</b>, the arbitrator has the authority to grant any
                remedy that would otherwise be available in court. Notwithstanding the foregoing, you and SpaceJammit are NOT
                required to arbitrate any dispute in which either party seeks equitable or other relief for (1) the alleged unlawful
                use of copyrights, trademarks, trade names, logos, trade secrets or patents or (2) allegedly criminal conduct under
                laws of the United States or any state thereof including, but not limited to, criminal laws addressing pornography,
                exploitation, obscenity, or hate speech. </p>
            
            
            <p>The arbitrator will not be bound by rulings in prior arbitrations involving different SpaceJammit users but is bound
                by rulings in prior arbitrations involving the same SpaceJammit user to the extent required by applicable law. The
                arbitrator’s award will be final and binding and judgment on the award rendered by the arbitrator may be entered in
                any court having jurisdiction thereof. <b> YOU AND SpaceJammit AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
                    OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS PART OF ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
                    UNLESS BOTH YOU AND SPACEJAMMIT AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE
                    PERSON’S OR PARTY’S CLAIMS AND MAY NOT
                    OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY
                    AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
                    SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL
                    CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER SPACEJAMMIT USERS.
                </b>
            </p>
            <p>Unless you and SpaceJammit agree otherwise, in the event that a court decides that any part of this Section is
                invalid or unenforceable, you agree that any claim or dispute that has arisen or may arise between you and
                SpaceJammit must be resolved exclusively by a state or federal court located in New Castle County, Delaware. The
                remainder of the <b>TERMS OF USE</b> will continue to apply. You and SpaceJammit agree to submit to the personal
                jurisdiction of the courts located within New Castle County, Delaware for the purpose of litigating all such claims
                or disputes. </p>
            
            
            <h3>International Users</h3>
            <p>SpaceJammit is located in the State of Delaware in the United States of America. If you access the Services from a
                country other than the United States, you agree that your transactions with SpaceJammit occur in the United States.
                SpaceJammit users may not have full services outside of the United States. You are responsible for compliance with
                all applicable laws, rules, and regulations applicable to your use of the Services. </p>
            
            
            <h3>Subscription Plans and Commissions</h3>
            <p>SpaceJammit offers a variety of account plans designed to reflect the diverse needs of our users, each with its own
                features, descriptions, benefits, and functionality. A description of each account plan that is currently offered by
                SpaceJammit is available at www.spacejammit.com. By signing up and agreeing to purchasing a subscription, </p>
            
            <ul>
                <li>You agree to pay all applicable subscription fees and other charges in accordance with your selected account
                    plan, pursuant to the renewal and termination provisions described below (including any applicable taxes, such
                    as sales tax), and
                </li>
                <li>You agree that you are only entitled to the features and services applicable to the account plan that you select
                    at the time you purchase a subscription.
                </li>
            </ul>
            <p>You may not transfer or assign your subscription to another individual, entity or SpaceJammit user unless such
                transfer or assignment is expressly permitted by the <b>TERMS</b> of your account plan.
            </p>
            <p>SpaceJammit may provide certain users with complimentary or discounted subscriptions. Any user accessing the Services
                by
                using a complimentary or discounted subscription is bound by the <b>TERMS OF USE</b> and any applicable terms and
                conditions of
                these SpaceJammit Service <b>TERMS</b> (including SpaceJammit’s right to terminate or cancel such subscriptions).
            </p>
            <p>SpaceJammit commission program is bound by the individual, entity, or group’s individualized commission contract.
                Relevant information needed to transmit funds and submit necessary tax forms are required to be compensated for any
                commissions under the commission program.
            </p>
            
            
            
            <h3>Term and Automatic Renewal
            </h3>
            <p>Your subscription is valid commencing on the date in which SpaceJammit provides you with access to the Services
                pursuant to its receipt of your valid Payment Information (the “Commencement Date”) and will last for the duration
                of the term that you select at the time of your registration for SpaceJammit Services.</p>
            
            <p><b>YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW AT THE END OF EACH SUBSCRIPTION TERM FOR SUCCESSIVE SUBSCRIPTION TERMS
                    OF THE SAME DURATION AS THE SUBSCRIPTION TERM ORIGINALLY SELECTED UNLESS THE SUBSCRIPTION IS TERMINATED AND/OR
                    CANCELED BY YOU OR SPACEJAMMIT PRIOR TO THE END OF SUCH SUBSCRIPTION TERM. SUCH RENEWAL WILL OCCUR AUTOMATICALLY
                    ON THE APPLICABLE ANNIVERSARY OF THE COMMENCEMENT DATE. YOU MAY CANCEL YOUR SUBSCRIPTION AT ANY TIME PRIOR TO
                    THE END OF THE SUBSCRIPTION TERM THROUGH YOUR ACCOUNT SETTINGS IN THE SUBSCRIPTION SECTION. YOU MAY CHANGE YOU
                    TERM IN YOUR ACCOUNT PROFILE / MISSION CONTROL. ANY NEWLY SELECTED TERM RESETS THE COMMENCEMENT DATE WITH ALL
                    OTHER AUTOMATIC RENEWAL PROCESSES ENFORCED.
                </b></p>
            <p>You agree that upon such renewal, the payment method selected at time of registration will be billed the applicable
                subscription fee for your account plan then in effect at the time of renewal. If you disagree with these automatic
                renewal provisions, your sole remedy is to cancel or terminate your subscription. If your automatic renewal
                experiences a failure, is cancelled, is declined, or is rejected by your billing provider, SpaceJammit may cancel
                your services.
            </p>
            <p>You acknowledge that the amount of the recurring charge at the time of subscription renewal may differ from the
                subscription fees you originally paid for the SpaceJammit Services if: (i) the subscription fee for your account
                plan changes during your applicable subscription term, or (ii) if you change your account plan or subscription term
                during the previous subscription term (iii) if you add additional services selected after the commencement date
                (SpaceJammit reserves the right to make these additional services separate
                billing transactions). SpaceJammit will notify you of any changes to the subscription fee prior to the date on which
                your subscription term will renew. You hereby agree to any such revised subscription fees and your sole remedy if
                you disagree with such changes is to terminate your account and stop using the SpaceJammit Services.
            
            </p>
            <h3>Cancelation and Termination of Services
            </h3>
            <p>You may request to cancel or terminate your account plan at any time. Cancelation requests can be submitted in the
                SpaceJammit app or through the web browser interface. Termination requests can be submitted through the FAQ section.
                SpaceJammit will send an email confirming receipt of the cancel or terminate request and may request additional
                information from you prior to canceling or terminating your account plan. User plans are not eligible for refunds.
            </p>
            <p>Unfortunately, there are some situations where SpaceJammit will be forced to terminate your account plan. In these
                scenarios, SpaceJammit reserves the right to suspend or terminate your access to the Services with notice to you if:
            </p>
            <ul>
                <li>You are in breach of these <strong>TERMS</strong>,</li>
                <li>Your use of the Services would cause a real risk of harm or loss to us or other users,</li>
                <li>Have not accessed our Services for 12 consecutive months, or</li>
                <li>You are in violation of <strong>Acceptable Use </strong>guidelines.</li>
            </ul>
            <p>SpaceJammit will provide you with reasonable advance notice via the email address associated with your account to
                remedy the activity that prompted us to contact you and give you the opportunity to export your content from our
                Services. If after such notice you fail to take the steps we ask of you, we will terminate or suspend your access to
                the Services.</p>
            <p>We will not provide notice before termination where:
            </p>
            <ul>
                <li>You are in material breach of these <strong>TERMS OF USE</strong>,</li>
                <li>Doing so would cause us legal liability or compromise our ability to provide the Services to our other users, or
                </li>
                <li>We are prohibited from doing so by law.</li>
            </ul>
            <h3>Data Retention</h3>
            <p>Data retention and deletion will be followed, dictated, and executed by user plan guidelines. SpaceJammit uses third
                party vendors to host and store any media that is uploaded to SpaceJammit. We may require our vendors to maintain
                certain data standards with respect to your user content, but SpaceJammit does not guarantee that your user content
                will be always secure and/or available during the subscription term. SpaceJammit does not guarantee that your user
                content will be available on the Services after the termination of your account plan, irrespective of the reason for
                such termination. Please note that when user content or other files are deleted (or your account is cancelled),
                those files will be deleted based on the type of request as outlined below:
            </p>
            <p><b>For cancelation requests –</b> Files will be deleted at the end of the renewal period and user files will be
                permanently deleted (irrecoverable) <b>75 days</b> after the renewal period ends. Between now and the end of the
                subscription plan, users will have the opportunity to download their files.</p>
            <p><b>For terminate requests –</b> Files will be deleted at the end of the renewal period and will be permanently
                deleted (irrecoverable) after the renewal period ends which will be 15 days post the termination date. </p>
            
            
            
            
            
            <h3>Questions and Comments</h3>
            <p style="text-align:justify">Our goal is for each user to be happy with the SpaceJammit experience. If you have any
                questions, concerns, complaints or comments in any way related to your use of the Services, please send
                correspondence on technical questions to <a href="mailto:tech@spacejammit.com"
                    style="text-decoration:none"><u>tech@spacejammit.com</u></a> and general support questions to <a
                    href="mailto:support@spacejammit.com" style="text-decoration:none"><u>support@spacejammit.com</u></a>.</p>
         <p>Revision Date: 2/2/2022</p>
                </div>
  </div>
</div>
<footer class="copyright">©Space Jammit, 2021. All rights reserved.</footer>
