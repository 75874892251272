import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GalaxyComponent } from './components/dashboard/galaxy/galaxy.component';
import { ErrorComponent } from './components/error/error.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { GetLoggedData } from './services/common/common.model';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'sign-up', component: SignUpComponent, canActivate: [AuthGuard] },
  { path: 'forgot/:type', component: ForgotComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard] },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'space', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'p-view', loadChildren: () => import('./components/public/public.module').then( m => m.PublicModule) },
  { path: 'error/:msg', component: ErrorComponent },
  { path: 'embed/:starId', loadChildren: () => import('./embed/embed.module').then(m => m.EmbedModule) },
  { path: '**', redirectTo: '/login' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
