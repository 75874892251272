import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { paths } from 'src/app/constants/routing.paths';
import { endpoints } from '../core/http/api.endpoints';
import { HttpRequestService } from '../core/http/http-request.service';
import { storage_keys } from '../core/storage/storage.keys';
import { StorageService } from '../core/storage/storage.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { ChangePasswordPayload, LoginPayload, RegisterPayload, ResetPasswordPayload, VerifyOtpPayload } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpRequestService,
    private storage: StorageService,
    private router: Router,
    private subscriptionSevice: SubscriptionService
  ) { }

  register(payload: RegisterPayload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.register, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  verifyOtp(payload: VerifyOtpPayload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.verifyOtp, payload).subscribe(
        (res) => {
          this.handleAuthentication(res.userData);
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  handleAuthentication(userData: any) {
    if (userData) {

      this.storage.set(storage_keys.authToken, userData.authToken).then(
        () => {
          this.storage.set(storage_keys.userData, userData).then(
            () => {

              this.subscriptionSevice.getSubscriptionStatus(true);

            }
          );
        }
      );
    }
  }

  resendOtp(email: string) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.resendOtp, { 'email': email }).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  login(payload: LoginPayload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.login, payload).subscribe(
        (res) => {
          this.handleAuthentication(res.userData);
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  logout(payload:any) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.logout,payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    }).finally(
      () => {
        this.storage.clearStorageForLogout().then(
          () => {
            this.router.navigate([paths.l.path]);
          }
        );
      }
    );
  }

  getProfileDetails() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.auth.profile).subscribe(
        (res) => {
          if (res && res.userData) {
            this.storage.set(storage_keys.userData, res.userData);
          }
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getStorageStats() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.auth.stats).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateProfile(payload: FormData) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.profile, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  changePassword(payload: ChangePasswordPayload) {
    return new Promise((resolve, reject) => {
      this.http.put(endpoints.auth.changePassword, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  forgotUsername(payload: { 'email': string }) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.forgotUsername, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  forgotPassword(payload: { 'userName': string }) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.forgotPassword, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  resetPassword(payload: ResetPasswordPayload) {
    return new Promise((resolve, reject) => {
      this.http.post(endpoints.auth.resetPassword, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getCurrentAvailableSpace() {
    return new Promise((resolve, reject) => {
      this.http.get(endpoints.auth.availableSpace).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
