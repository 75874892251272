<div class="stick-header header-center header-bg">
  <div class="container">
    <div class="stick-in">
      <button type="button" [routerLink]="['/space/mission-control']" class="back-btn">
      </button>
      <h2 class="page-title">Astronaut Status</h2>
      <div class="head-ic">
        <button type="button" (click)="logout()" class="right-btn logout">
          <span>Sign out </span>
          <img src="assets/icons/logout.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</div>
<div class="sj-info subscriptions" style="background-image: url(assets/images/bg-img.jpg);">
  <div class="sj-container">
    <ngx-slick-carousel *ngIf="slides?.length" class="carousel" (init)="onSlickInit(slickModal)"
      #slickModal="slick-carousel" [config]="imageConfig">
      <div ngxSlickItem *ngFor="let item of slides" [id]="item" class="slide">
        <ng-container [ngSwitch]="item">
          <div *ngSwitchCase="'space_pilot'" class="plan-info pilot-plan">
            <div class="plan-bg">
              <div class="plan-header">
                <img src="assets/images/avatarblue.png" class="plan-img">
                <h3 class="plan-text"><span>Space</span> Pilot</h3>
              </div>
              <div class="plan-content">
                <ul>
                  <li>Batch file uploads for hosting</li>
                  <li>Ability to share an entire folder </li>
                  <li>Upload & Download on any device</li>
                  <li>14-day recovery on deleted files </li>
                  <li>Timed access for sharing files ( 24hr / 48hr / unlimited )</li>
                </ul>
              </div>
              <div class="space-pilot">
                <h4 class="note-text">Receive 2 months free with annual plan signup</h4>
              </div>
              <div class="plan-price">
                <ul ngbNav #nav="ngbNav"  class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>Monthly</a>
                    <ng-template ngbNavContent>
                      <div class="upgrade">
                        <h4 class="subscribe_title">All plans are renewed on monthly basis</h4>
                        <div class="two-btn">
                          <ng-template ngFor let-item [ngForOf]="pricesPerStype?.space_pilot" let-i="index"
                            [ngForTrackBy]="trackByFn">
                            <button *ngIf="item.billingCycle == 'monthly'" type="button" class="btn"
                            (click)="selectedProductPlan(item?.productId)"
                            [ngClass]="item?.productId == selectedProductId ? 'btn-active' : item?.spaceGB==activePlan ? 'btn-active' :''">
                              {{item?.spaceGB}} GB<br>${{item?.priceUSD}}
                            </button>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Annual</a>
                    <ng-template ngbNavContent>
                      <div class="upgrade">
                        <h4 class="subscribe_title">All plans are renewed on yearly basis</h4>
                        <div class="two-btn">
                          <ng-template ngFor let-item [ngForOf]="pricesPerStype?.space_pilot" let-i="index"
                            [ngForTrackBy]="trackByFn">
                            <button *ngIf="item.billingCycle == 'yearly'" type="button" class="btn"
                              (click)="selectedProductPlan(item?.productId)"
                              [ngClass]="item?.productId == selectedProductId ? 'btn-active' : item?.spaceGB==activePlan ? 'btn-active' :''">
                              {{item?.spaceGB}} GB<br>${{item?.priceUSD}}
                            </button>
                          </ng-template>
                          <!-- [ngClass]="{'btn-active' : item?.productId == selectedProductId, 'btn-active' :item?.spaceGB==activePlan }" -->
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  
                </ul>
                
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>

            
            <div class="plan-button" *ngIf="subscriptionStatus?.purchasePlatform == ''">
              <button type="button" class="btn btn-full"
                [disabled]="selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested"
                (click)="proceedSubscription(selectedProductId)">
                Subscribe
              </button>
            </div>
            <div class="plan-button" *ngIf="selectedProductId && subscriptionStatus?.purchasePlatform == 'WEB'">
              <button type="button" class="btn btn-full"
                [disabled]="selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested"
                (click)="proceedSubscription(selectedProductId)">
                {{selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested && subscriptionStatus?.subscrptionStatus!='expired'
                ? 'Subscribed' : 'Subscribe'}}
              </button>
            </div>

            <div class="plan-button"
            *ngIf="subscriptionStatus?.purchasePlatform.toLowerCase() == 'android' || subscriptionStatus?.purchasePlatform.toLowerCase() == 'ios'">
            <button type="button" class="btn btn-full" [disabled]="!selectedProductId"
              (click)="proceedSubscription(selectedProductId)">
               {{subscriptionStatus?.subscrptionStatus=='expired' ? 'Subscribe' : (subscriptionStatus?.subscrptionStatus=='Cancelled' || subscriptionStatus?.subscrptionStatus=='canceled') && subscriptionStatus?.daysRemains < (-15) ? 'Subscribe' :  (subscriptionStatus?.subscrptionStatus=='Cancelled' || subscriptionStatus?.subscrptionStatus=='canceled') && subscriptionStatus?.daysRemains > (-15) ?'Subscribed' :subscriptionStatus?.subscrptionStatus=='active' ? 'Subscribed':'Subscribe'}}

            </button>
          </div>
          </div>
          <div *ngSwitchCase="'space_traveler'" class="plan-info traveler-plan">
            <div class="plan-bg">
              <div class="plan-header">
                <img src="assets/images/avatarpink.png" class="plan-img">
                <h3 class="plan-text"><span>Space</span> Traveler</h3>
              </div>
              <div class="plan-content">
                <ul>
                  <li>Basic “no frills” file hosting</li>
                  <li>Basic “no frills” file sharing</li>
                  <li>Upload & Download on any device</li>
                  <li>7-day recovery on deleted files</li>
                  <li>Extended storage plans available</li>
                </ul>
              </div>
              <div class="space-traveler">
                <h4 class="note-text">Receive 2 months free with annual plan signup</h4>
              </div>
              <div class="plan-price">
                <ul ngbNav #nav="ngbNav"  class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>Monthly</a>
                    <ng-template ngbNavContent>
                      <div class="upgrade">
                        <h4 class="subscribe_title">All plans are renewed on monthly basis</h4>
                        <div class="two-btn">
                          <ng-template ngFor let-item [ngForOf]="pricesPerStype?.space_traveler" let-i="index"
                            [ngForTrackBy]="trackByFn">
                            <button *ngIf="item.billingCycle == 'monthly'" type="button" class="btn"
                            (click)="selectedProductPlan(item?.productId)"
                            [ngClass]="item?.productId == selectedProductId ? 'btn-active' : item?.spaceGB==activePlan ? 'btn-active' :''">
                              {{item?.spaceGB}} GB<br>${{item?.priceUSD}}
                            </button>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Annual</a>
                    <ng-template ngbNavContent>
                      <div class="upgrade">
                        <h4 class="subscribe_title">All plans are renewed on yearly basis</h4>
                        <div class="two-btn">
                          <ng-template ngFor let-item [ngForOf]="pricesPerStype?.space_traveler" let-i="index"
                            [ngForTrackBy]="trackByFn">
                            <button *ngIf="item.billingCycle == 'yearly'" type="button" class="btn"
                            (click)="selectedProductPlan(item?.productId)"
                              [ngClass]="item?.productId == selectedProductId ? 'btn-active' : item?.spaceGB==activePlan ? 'btn-active' :''">
                              {{item?.spaceGB}} GB<br>${{item?.priceUSD}}
                            </button>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
            <div class="plan-button" *ngIf="subscriptionStatus?.purchasePlatform == ''">
              <button type="button" class="btn btn-full"
                [disabled]="selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested"
                (click)="proceedSubscription(selectedProductId)">
                Subscribe
              </button>
            </div>
            <div class="plan-button" *ngIf="selectedProductId && subscriptionStatus?.purchasePlatform == 'WEB'">
              <button type="button" class="btn btn-full"
                [disabled]="selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested && subscriptionStatus?.subscrptionStatus!='expired'"
                (click)="proceedSubscription(selectedProductId)">
                {{selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested && subscriptionStatus?.subscrptionStatus!='expired'
                ? 'Subscribed' : 'Subscribe'}}
              </button>
            </div>

            <div class="plan-button"
            *ngIf="subscriptionStatus?.purchasePlatform.toLowerCase() == 'android' || subscriptionStatus?.purchasePlatform.toLowerCase() == 'ios'">
            <button type="button" class="btn btn-full" [disabled]="!selectedProductId"
              (click)="proceedSubscription(selectedProductId)">
               {{subscriptionStatus?.subscrptionStatus=='expired' ? 'Subscribe' : (subscriptionStatus?.subscrptionStatus=='Cancelled' || subscriptionStatus?.subscrptionStatus=='canceled') && subscriptionStatus?.daysRemains < (-15) ? 'Subscribe' :  (subscriptionStatus?.subscrptionStatus=='Cancelled' || subscriptionStatus?.subscrptionStatus=='canceled') && subscriptionStatus?.daysRemains > (-15) ?'Subscribed' :subscriptionStatus?.subscrptionStatus=='active' ? 'Subscribed':'Subscribe'}}

            </button>
          </div>
          </div>
          <div *ngSwitchCase="'space_titan'" class="plan-info titan-plan">
            <div class="plan-bg">
              <div class="plan-header">
                <img src="assets/images/avatarpurple.png" class="plan-img">
                <h3 class="plan-text"><span>Space</span> Titan</h3>
              </div>
              <div class="plan-content">
                <ul>
                  <li>All features from Space Traveler & Pilot</li>
                  <li>30-day recovery on deleted files </li>
                  <li>Timed access for sharing files ( 24hr / 48hr / unlimited ) </li>
                  <li>Admin premission control for files</li>
                  <li>Add up to 50 users </li>
                </ul>
              </div>
              <div class="space-titan">
                <h4 class="note-text">Receive 2 months free with annual plan signup</h4>
              </div>
              <div class="plan-price">
                <ul ngbNav #nav="ngbNav"  class="nav-tabs">
                  <li ngbNavItem>
                    <a ngbNavLink>Monthly</a>
                    <ng-template ngbNavContent>
                      <div class="upgrade">
                        <h4 class="subscribe_title">All plans are renewed on monthly basis</h4>
                        <div class="two-btn">
                          <ng-template ngFor let-item [ngForOf]="pricesPerStype?.space_titan" let-i="index"
                            [ngForTrackBy]="trackByFn">
                            <button *ngIf="item.billingCycle == 'monthly'" type="button" class="btn"
                            (click)="selectedProductPlan(item?.productId)"
                              [ngClass]="item?.productId == selectedProductId ? 'btn-active' : item?.spaceGB==activePlan ? 'btn-active' :''">
                              {{item?.spaceGB}} GB<br>${{item?.priceUSD}}
                            </button>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Annual</a>
                    <ng-template ngbNavContent>
                      <div class="upgrade">
                        <h4 class="subscribe_title">All plans are renewed on yearly basis</h4>
                        <div class="two-btn">
                          <ng-template ngFor let-item [ngForOf]="pricesPerStype?.space_titan" let-i="index"
                            [ngForTrackBy]="trackByFn">
                            <button *ngIf="item.billingCycle == 'yearly'" type="button" class="btn"
                            (click)="selectedProductPlan(item?.productId)"
                            [ngClass]="item?.productId == selectedProductId ? 'btn-active' : item?.spaceGB==activePlan ? 'btn-active' :''">
                              {{item?.spaceGB}} GB<br>${{item?.priceUSD}}
                            </button>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>

            <div class="plan-button" *ngIf="subscriptionStatus?.purchasePlatform == ''">
              <button type="button" class="btn btn-full"
                [disabled]="selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested"
                (click)="proceedSubscription(selectedProductId)">
                Subscribe
              </button>
            </div>
            <div class="plan-button" *ngIf="selectedProductId && subscriptionStatus?.purchasePlatform == 'WEB'">
              <button type="button" class="btn btn-full"
                [disabled]="selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested && subscriptionStatus?.subscrptionStatus!='expired'"
                (click)="proceedSubscription(selectedProductId)">
                {{selectedProductId == subscriptionStatus?.planInfo?.productId && !subscriptionStatus?.isCancelRequested && subscriptionStatus?.subscrptionStatus!='expired'
                ? 'Subscribed' : 'Subscribe'}}
              </button>
            </div>

            <div class="plan-button"
            *ngIf="subscriptionStatus?.purchasePlatform.toLowerCase() == 'android' || subscriptionStatus?.purchasePlatform.toLowerCase() == 'ios'">
            <button type="button" class="btn btn-full" 
              (click)="proceedSubscription(selectedProductId)">
               {{subscriptionStatus?.subscrptionStatus=='expired' ? 'Subscribe' : (subscriptionStatus?.subscrptionStatus=='Cancelled' || subscriptionStatus?.subscrptionStatus=='canceled') && subscriptionStatus?.daysRemains < (-15) ? 'Subscribe' :  (subscriptionStatus?.subscrptionStatus=='Cancelled' || subscriptionStatus?.subscrptionStatus=='canceled') && subscriptionStatus?.daysRemains > (-15) ?'Subscribed' :subscriptionStatus?.subscrptionStatus=='active' ? 'Subscribed':'Subscribe'}}

            </button>
          </div>
          </div>
        </ng-container>
      </div>
    </ngx-slick-carousel>
  </div>
</div>

<ng-template #subscriptionInfoModal let-modal>
  <div class="modal-body">
    <div class="message">
      <span class="message-img">
        <img src="assets/images/beamAvatar.png">
      </span>
      <span class="message-top message-success-top">
        <h2>{{subscriptionInfoTitle | titlecase}}</h2>
        <p>{{subscriptionInfoMsg}}</p>
      </span>
      <div class="message-btn">
        <button type="button" (click)="modal.dismiss(); selectedProductId = subscriptionStatus.planInfo.productId"
          class="btn">Cancel</button>
        <button type="button" (click)="modifySubscription()" class="btn">Yes, Subscribe</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #alertModal let-modal>
  <div class="modal-body">
    <div class="message">
      <span class="message-img">
        <img src="assets/images/beamAvatar.png">
      </span>
      <span class="message-top error">
        <h2>{{alertTitle}}</h2>
        <p>{{alertMsg}}</p>
      </span>
      <div class="message-btn">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn">Cancel</button> -->
        <button type="button" class="btn" (click)="modal.dismiss()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #restoreMissionAlertModal let-modal>
  <div class="modal-body">
    <div class="message">
      <span class="message-img">
        <img src="assets/images/beamAvatar.png">
      </span>
      <span class="message-top message-success-top">
        <!-- <h2>Request Submitted</h2> -->
        <p>You have requested to "Cancel Mission". Please "Restore Mission" to make any changes to your existing plan.
        </p>
      </span>
      <div class="message-btn">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn">Cancel</button> -->
        <button type="button" (click)="modal.dismiss()" class="btn">Ok</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #accountExpiredForMember let-modal>
  <div class="modal-body">
    <div class="message">
      <span class="message-img">
        <img src="assets/images/beamAvatar.png">
      </span>
      <span class="message-top message-success-top">
        <h2>Account Expired</h2>
        <p>Your access has been ended. Please contact to admin.
        </p>
      </span>
      <div class="message-btn">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn">Cancel</button> -->
        <button type="button" (click)="modal.dismiss();logout();" class="btn">Ok</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #defrenPlatForm let-modal>
  <div class="modal-body">
    <div class="message">
      <span class="message-img">
        <img src="assets/images/beamAvatar.png">
      </span>
      <span class="message-top message-success-top">
        <p>Your plan is already active from the app,</p>
        <p>Once it's over, then you will be able to upgrade your account
        </p>
      </span>
      <div class="message-btn">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn">Cancel</button> -->
        <button type="button" (click)="modal.dismiss();" class="btn">Ok</button>
      </div>
    </div>
  </div>
</ng-template>