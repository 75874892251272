import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { SUBSCRIPTIONS } from "src/app/constants/constant";
import { DOCUMENT, Location } from '@angular/common';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ModifySubscription } from 'src/app/services/subscription/subscriptions.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { paths } from 'src/app/constants/routing.paths';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/services/core/storage/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  slickCarousel: SlickCarouselComponent;
  @ViewChild('alertModal') alertModal: any;
  @ViewChild('subscriptionInfoModal') subscriptionInfoModal: any;
  @ViewChild('restoreMissionAlertModal') restoreMissionAlertModal;
  @ViewChild('accountExpiredForMember') accountExpiredForMember;
  @ViewChild('defrenPlatForm') defrenPlatForm;
  pricesPerStype: any = {};
  alertMsg: string;
  alertTitle: string;
  subscriptionInfoMsg: string;
  subscriptionInfoTitle: string;
  expired: boolean;
  selectedProductId: string = "";
  planList: Array<any> = [];
  ngbNavItemTabb = ''
  activePlan = '';


  constructor(
    private commonService: CommonService,
    private subscriptionService: SubscriptionService,
    public _location: Location,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private storege: StorageService
  ) {
    this.getProfileDetail();
  }

  ngOnInit(): void {

  }

  onSlickInit(slickCarousel) {
    this.slickCarousel = slickCarousel;
  }

  imageConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "arrows": true,
    'fade': true,
    'dots': false
  }

  subscriptionStatus: any;
  slides = []
  getSubscritptionSatus() {
    this.commonService.presentSpinner();
    let status = null;
    this.pricesPerStype = {};
    this.selectedProductId = "";
    this.subscriptionStatus == null;
    this.subscriptionService.getSubscriptionStatus(false).then(
      (subscriptionStatus: any) => {
        if (subscriptionStatus) {
          // If Cancellation is requested and account is not expired including grace/renewal period
          if (subscriptionStatus.isCancelRequested && !subscriptionStatus.accountExpiredWithRenewalPeriod && subscriptionStatus.purchasePlatform == 'WEB') {
            this.openModal(this.restoreMissionAlertModal);
            // this.router.navigate([paths.d.path, paths.d.child.as.path]);
          }
          else {
            status = subscriptionStatus;
            if (subscriptionStatus.currentStatus == 'active' && subscriptionStatus.purchasePlatform == 'WEB') {
              this.expired = false;
            } else {
              this.expired = true;
            }

            this.getSubscriptionPlans(status);
          }
        } else {
          this.goToMissionControl();
        }
      }
    ).catch(
      (error) => {
        this.commonService.dismissSpinner();
        this.goToMissionControl();
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }


  getSubscriptionPlans(subscriptionStatus: any) {
    this.subscriptionService.getSubscriptionPlans().then(
      ({ plansList }) => {
        if (plansList) {
          this.pricesPerStype[SUBSCRIPTIONS.SPACE_PILOT] = plansList.filter(x => x.planCode == SUBSCRIPTIONS.SPACE_PILOT);
          this.pricesPerStype[SUBSCRIPTIONS.SPACE_TITAN] = plansList.filter(x => x.planCode == SUBSCRIPTIONS.SPACE_TITAN);
          this.pricesPerStype[SUBSCRIPTIONS.SPACE_TRAVELLER] = plansList.filter(x => x.planCode == SUBSCRIPTIONS.SPACE_TRAVELLER);
          this.subscriptionStatus = subscriptionStatus;
          if ((this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'android' || this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'ios') && this.subscriptionStatus.subscrptionStatus == 'expired') {
            this.selectedProductId = '';
            this.activePlan = subscriptionStatus.planInfo.spaceGB;

          }

          else
            if ((this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'android' || this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'ios') && (this.subscriptionStatus.subscrptionStatus == 'active' || (this.subscriptionStatus.subscrptionStatus == 'Cancelled' || this.subscriptionStatus.subscrptionStatus == 'canceled') && (this.subscriptionStatus.daysRemains < (-15)))) {
              this.selectedProductId = '';
              this.activePlan = subscriptionStatus.planInfo.spaceGB;

            }

            else {
              this.selectedProductId = subscriptionStatus.planInfo.productId || "";
              this.activePlan = '';
            }

          this.planList = plansList;
          this.planList.filter((item) => {
            if ((item.billingCycle == subscriptionStatus.planInfo.billingCycle) && (item.planCode == subscriptionStatus.planInfo.planCode) && (item.spaceGB == subscriptionStatus.planInfo.spaceGB)) {
              this.ngbNavItemTabb = subscriptionStatus.planInfo.billingCycle
              this.selectedProductPlan(item.productId);
            } else {
              this.ngbNavItemTabb = subscriptionStatus.planInfo.planType

            }
          })

        } else {
          this.goToMissionControl();
        }
      }
    ).catch(
      (error) => {
        this.goToMissionControl();
      }
    ).finally(
      () => {
        if (this.subscriptionStatus) {
          switch (this.subscriptionStatus.planInfo.planCode) {
            case SUBSCRIPTIONS.SPACE_PILOT:
              this.slides = ['space_pilot', 'space_traveler'];
              break;

            case SUBSCRIPTIONS.SPACE_TRAVELLER:
              this.slides = ['space_traveler', 'space_pilot'];
              break;

            case SUBSCRIPTIONS.SPACE_TITAN:
              this.slides = ['space_titan'];
              break;

            case SUBSCRIPTIONS.SPACE_TRIAL:
              this.slides = ['space_pilot', 'space_titan', 'space_traveler'];
              break;
          }
        }
        this.commonService.dismissSpinner();
      }
    )
  }

  selectedProductPlan(planId) {
    this.selectedProductId = planId;
    this.activePlan = '';
  }


  proceedSubscription(id) {
    this.selectedProductId = id;
    if (!this.selectedProductId.length || !this.selectedProductId) {
      return;
    }
    if (this.subscriptionStatus.planInfo.planCode == SUBSCRIPTIONS.SPACE_TRIAL) {
      this.checkoutForSubscription();
    }
    else {
      if (this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'web') {
        if (this.subscriptionStatus.subscrptionStatus.toLowerCase() == 'active') {
          this.modifySubscriptionAlert();
        }

        if (this.subscriptionStatus.subscrptionStatus.toLowerCase() == 'canceled' || this.subscriptionStatus.subscrptionStatus.toLowerCase() == 'expired') {
          this.checkoutForSubscription();
        }



      }

      if (this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'android' || this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'ios') {

        if (this.subscriptionStatus.subscrptionStatus == 'active') {
          this.openModal(this.defrenPlatForm)

        }
        if (this.subscriptionStatus.subscrptionStatus.toLowerCase() == 'canceled') {
          this.openModal(this.defrenPlatForm)

        }

        if (this.subscriptionStatus.subscrptionStatus.toLowerCase() == 'expired') {
          this.checkoutForSubscription();
        }
      }
    }

  }

  openAlrtModal() {
    this.openModal(this.defrenPlatForm)

  }

  checkoutForSubscription() {
    this.commonService.presentSpinner();
    this.subscriptionService.checkoutForSubscription(this.selectedProductId).then(
      ({ checkoutUrl }) => {
        if (checkoutUrl) {
          this.document.location.href = checkoutUrl;
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    );
  }

  modifySubscriptionAlert() {
    this.commonService.presentSpinner();
    this.authService.getStorageStats().then(
      ({ statistics, purchasedSpace, usedSpace }) => {
        let selectedPlan = this.planList.find(plan => plan.productId == this.selectedProductId);
        if (selectedPlan.spaceGB > usedSpace) {
          if ((selectedPlan.spaceGB == this.subscriptionStatus.planInfo.spaceGB) && this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'web') {
            this.subscriptionInfoTitle = 'Update';
            this.subscriptionInfoMsg = 'Space Astronaut – Your storage stays the same but you are changing your plan. Click yes to continue with your subscription.';
            this.openModal(this.subscriptionInfoModal);
          } else if ((selectedPlan.spaceGB > this.subscriptionStatus.planInfo.spaceGB) && this.subscriptionStatus?.purchasePlatform.toLowerCase() == 'web') {
            this.subscriptionInfoTitle = 'upgrade';
            this.subscriptionInfoMsg = 'Space Astronaut - You are about to increase your storage limit. Click yes to continue with your subscription.';
            this.openModal(this.subscriptionInfoModal);
          } else if (selectedPlan.spaceGB < this.subscriptionStatus.planInfo.spaceGB) {
            this.subscriptionInfoTitle = 'downgrade';
            this.subscriptionInfoMsg = 'Space Astronaut - You are about to decrease your storage limit. Click yes to continue with your subscription.';
            this.openModal(this.subscriptionInfoModal);
          } else if ((selectedPlan.spaceGB > this.subscriptionStatus.planInfo.spaceGB) && this.subscriptionStatus?.purchasePlatform.toLowerCase() != 'web') {
            this.checkoutForSubscription();
          } else if ((selectedPlan.spaceGB == this.subscriptionStatus.planInfo.spaceGB) && this.subscriptionStatus?.purchasePlatform.toLowerCase() != 'web') {
            this.checkoutForSubscription();
          }
        } else {
          this.selectedProductId = this.subscriptionStatus.planInfo.productId || "";
          this.alertTitle = "Can't downgrade";
          this.alertMsg = "Sorry! You cannot downgrade your subscription, as you've already used upto the selected storage.";
          this.openModal(this.alertModal);
          // this.goToMissionControl();


        }
      }
    ).catch(
      (error) => {
        this.goToMissionControl();
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    );
  }

  modifySubscription() {
    let payload = new ModifySubscription({
      "action": "UPGRADE",
      "priceId": this.selectedProductId
    });
    this.commonService.presentSpinner();
    this.subscriptionService.modifySubscription(payload).then(
      (res) => {
        // 
        // let data = this.storege.getSavedData();
        // if (data.userData.isSubscribed == true) {
        //   if (data.subscription.planInfo.planName == "Space Pilot") {
        //     this.storege.set('SpacPilotCount','2');
        //   }

        //   if (data.subscription.planInfo.planName == "Space Titan") {
        //     this.storege.set('SpaceTitanCount','2');
        //   }

        //   if (data.subscription.planInfo.planName == "Space Traveler") {
        //     this.storege.set('SpaceTravelerCount','2');
        //   }

        // }
      }
    ).finally(
      () => {
        this.goToMissionControl();
        this.commonService.dismissSpinner();
        this.modalService.dismissAll();
      }
    );
  }

  goToMissionControl() {
    this.router.navigate([paths.d.path, paths.d.child.mc.path]);
  }

  openModal(modalId: any) {
    this.modalService.open(modalId, { centered: true, windowClass: 'terminate-mission-popup message-popup' });
  }

  userData: any;
  getProfileDetail() {
    this.authService.getProfileDetails().then(
      ({ userData }) => {
        if (userData) {
          this.userData = userData;
          userData.subscribedPlan.subscriptionExpired = true;
          switch (userData.accountType) {
            case 'shared':
              if (userData.isTeamAdmin) {
                this.getSubscritptionSatus();
              } else {

                // if the user is a team member
                if (userData.subscribedPlan.subscriptionExpired) {
                  this.openModal(this.accountExpiredForMember);
                } else {
                  this.goToMissionControl();
                }

              }
              break;

            case 'individual':
              this.getSubscritptionSatus();
              break;
          }

          // if the user is a team member
          // if (userData.accountType == 'shared' && userData.isTeamAdmin == false) {
          //   if (userData.subscriptionExpired) {

          //   } else {
          //     this.goToMissionControl();
          //   }
          // } else {
          //   this.getSubscritptionSatus();
          // }
        } else {
          this._location.back();
        }
      }
    ).catch(
      (error) => {
        this._location.back();
      }
    );
  }

  logout() {
    this.commonService.presentSpinner();
    let payload = {
      platform: "WEB",
      deviceUid: "NA"
    }
    this.authService.logout(payload).then(
      (res) => { }
    ).catch(
      (error) => { }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    );
  }

}
