<div class="sj-info" style="background-image: url(assets/images/bg-img.jpg);">
  <div class="sj-container">
    <div class="logo-info">
      <img src="assets/images/logo.png" class="logo">
      <h3>...any file, any place, anytime...</h3>
    </div>
    <div class="login" [formGroup]="resetPasswordForm">
      <div class="form-bg">
        <div class="form-info">
          <h2 class="form-title">Reset Password!!</h2>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="icon-password iconInput">
                  <input [type]="password ? 'text' : 'password' " NoLeadingWhitespaces formControlName="password"
                    class="form-control" placeholder="Enter Password">
                  <i (click)="showPassword('p')" class="custom-eye" [ngClass]="password ? 'sj-eye-open' : 'sj-eye'"></i>
                </div>

                <div class="error-feedback"
                  *ngIf="formSubmitted && (resetPasswordForm?.controls['password']?.errors?.required || resetPasswordForm?.controls['password']?.errors?.pattern)">
                  <p>
                    <i>Your SpaceJammit password must contain at least eight characters, a number, an uppercase letter, and a lowercase letter.</i>
                    <br>
                  </p>
                  <p>
                    <span id="progress-bar-container">
                      <span class="progress-bar-child progress"></span>
                      <span [ngStyle]="{'width': checkPasswordStrength() + '%'}"
                        class="progress-bar-child shrinker timelapse"></span>
                    </span><br>
                    <span>Password strength:
                      {{resetPasswordForm?.controls['password']?.invalid ? 'Not Acceptable' : 'Acceptable'}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <div class="icon-password iconInput">
                  <input [type]="cPassword ? 'text' : 'password' " NoLeadingWhitespaces
                    formControlName="confirmPassword" class="form-control" placeholder="Re-enter Password">
                  <i (click)="showPassword('c')" class="custom-eye"
                    [ngClass]="cPassword ? 'sj-eye-open' : 'sj-eye'"></i>
                </div>
                <ng-container *ngIf="formSubmitted">
                  <p *ngIf="resetPasswordForm?.controls['confirmPassword']?.errors?.required" class="error-feedback">Re-enter Password!
                  </p>
                  <p *ngIf="resetPasswordForm?.controls['confirmPassword']?.value && resetPasswordForm?.controls['confirmPassword']?.value !== resetPasswordForm?.controls['password']?.value"
                    class="error-feedback">
                    Password does not match!
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-btn">
        <button type="button" class="btn btn-s" (click)="resetPassword()">Reset Password</button>

      </div>
    </div>

  </div>
</div>
