import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { paths } from 'src/app/constants/routing.paths';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common/common.service';
import { storage_keys } from '../storage/storage.keys';
import { StorageService } from '../storage/storage.service';
import { endpoints } from './api.endpoints';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(
    private storage: StorageService,
    private router: Router,
    private commonService: CommonService
  ) { }

  intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    let savedData = this.storage.getSavedData();
    let headers = {};
    if (savedData && savedData[storage_keys.authToken]) {
      if (!request.url.includes(endpoints.auth.resetPassword) && request.url.includes(environment.baseUrl)) {
        headers['Authorization'] = savedData[storage_keys.authToken];
        if (request.url.endsWith(endpoints.space.uploadStar) && request.method.toLowerCase() == "post") {
          request = request.clone({
            responseType: "blob",
            "reportProgress": true
          });
          this.commonService.upload.next(0);
        }
      } 
      // else {
      //   request = request.clone({
      //     responseType: "blob",
      //     "reportProgress": true
      //   });
      //   this.commonService.download.next(0);
      // }
    }
    this.commonService.download.next(0);
    let interceptedRequest = request.clone({
      "headers": new HttpHeaders(headers)
    });
    return handler.handle(interceptedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event.type == HttpEventType.DownloadProgress) {
            this.commonService.download.next(Math.ceil((event.loaded / event.total) * 100));
          }
          if (event.type == HttpEventType.UploadProgress && interceptedRequest.url.endsWith(endpoints.space.uploadStar)) {
            this.commonService.upload.next(Math.ceil((event.loaded / event.total) * 100));
          }
          if (event.type == HttpEventType.UploadProgress) {
          }
        },
        (error: HttpErrorResponse) => {
          this.commonService.download.next(0);
          this.commonService.upload.next(0);
          if (error && error.status == 401) {
            this.storage.clearStorageForLogout().then(
              () => {
                this.router.navigate([paths.l.path]).then(
                  () => {
                    localStorage.clear();
                    this.commonService.showWarningToastr('Session expired. Please login!');
                  }
                );
              }
            )
          }
        }
      )
    );
  }
}
